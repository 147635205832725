/**
 * article模块接口列表
 */

import { get } from "@/utils/http"; // 导入http中创建的axios实例

const api = {
  /**
   * 登录
   * user_name 用户名
   * password 密码
   */
  login(params) {
    return get(`/user_login`, params);
  },

  /**
   * 注册
   * user_name 用户名
   * password 密码
   * invite_code  邀请码
   * email    邮箱
   * email_code   邮箱验证码
   */
  register(params) {
    return get(`/user_register`, params);
  },

  /**
   * 更新密码
   * user_name 用户名
   * password 密码
   * email    邮箱
   * email_code   邮箱验证码
   */
  password_update(params) {
    return get(`/user_password_update`, params);
  },

  /**
   * 修改邮箱
   * user_name 用户名
   * password 密码
   * email    邮箱
   * email_code   邮箱验证码
   */
  mail_update(params) {
    return get(`/user_mail_update`, params);
  },

  /**
   * 邮箱验证码
   * email    邮箱
   */
  get_mail_code(params) {
    return get(`/mailCode`, params);
  },

  /**
   * 得到信息
   * user_name
   */
  get_user_wallet(params) {
    return get(`/user_wallet`, params);
  },

  /**
   * 转账
   * user_name
   * tokonIwtEth          >0 中心服增加   <0 中心服减少
   * usdt          >0 中心服增加   <0 中心服减少
   */
  user_wallet_update(params) {
    return get(`/user_wallet_update`, params);
  },

  /**
   * web3授权
   * idx      授权方的idx
   * to       授权的地址
   * tokenId  授权的tokenId
   */
  web3_approve(params) {
    return get(`/web3Approve`, params);
  },

  /**
   * 微端下载
   * code
   */
  downloadInstall(params) {
    return get(`/downloadInstall`, params);
  },

  /**
   * 登录接口
   * channelId	1.二维码walletconnect扫码登录 2.钱包登录metamask 3.传统账密登录 ,具体见ChannelEnum结构
    account	账号名
    pwd	密码
    sign	md5加密值
    timestamp	时间戳 毫秒
   */
  loginByChannel(params) {
    return get(`/loginByChannel`, params);
  },

  /**
   * 国服登录
   * @param {*} params
   * @returns
   */
  zhLogin(params) {
    return get(`/zhLogin`, params);
  },

  /**
   * .web端账号绑定接口 
   * @param {*} params 
   * 参数名	类型	可否为空	是否参与加密	说明
   * 
   * token	string	否	是	登录成功后获取的令牌
    account	string	否	是	账号名
    pwd	string	否	否	密码
    email	string	否	是	邮箱
    emailCode	string	否	否	邮箱验证码
    address	string	否	是	钱包地址
    srcInviteCode	string	否	否	我上级的邀请码
    sign	string	否	否	md5加密值
    timestamp	string	否	是	时间戳 毫秒
   * @returns 
   */
  bindAccountWithWallet(params) {
    return get(`/bindAccountWithWallet`, params);
  },

  /**
   * 账号信息接
   * @param {*} params 
   * 参数名	类型	可否为空	是否参与加密	说明
    token	string	否	是	登录成功后获取的令牌
    sign	string	否	否	md5加密值
    timestamp	string	否	是	时间戳 毫秒
   * @returns 
   */
  accountInfo(params) {
    return get(`/accountInfo`, params);
  },

  /**
   * 账号拉新积分明细
   * @param {*} params 
   * 参数名	类型	是否必填	是否参与加密	说明
    pageSize	int	否	否	分页的单页最大记录数，-1代表不分页,获取所有数据,默认是10条
    curIdx	int	否	否	当前第几页，默认从0开始
    token	string	否	是	登录成功后获取的令牌
    sign	string	否	否	md5加密值
    timestamp	string	否	是	时间戳 毫秒
   * @returns 
   */
  accountScoreDetails(params) {
    return get(`/accountScoreDetails`, params);
  },

  /**
   * 
   * @param {*} params 
   * pageSize	int	是	否	分页的单页最大记录数，-1代表不分页,获取所有数据
      curIdx	int	是	否	当前第几页，默认从0开始
      timestamp	string	是	是	时间戳 毫秒
      tab	int	是	否	tab标签
      tags	string	是	否	逗号分隔字符串
   * @returns 
   */
  recommendList(params) {
    return get(`/recommendList`, params);
  },

  shopList(params) {
    return get(`/zhShopPannel`, params);
  },

  /**
   * 任务列表
   * @param {*} params token,tabId
   * @returns 
   */
  zhTaskPannel(params) {
    return get(`/zhTaskPannel`, params);
  },

  /**
   * 任务奖励
   * @param {*} params  token,taskId
   * @returns 
   */
  zhTaskReward(params) {
    return get(`/zhTaskReward`, params);
  },

  /**
   * 任务完成
   * @param {*} params token,taskId
   * @returns 
   */
  zhTaskEvent(params) {
    return get(`/zhTaskEvent`, params);
  },
  
  /**
   * 生成邀请码
   * @param {*} params token
   */
  zhGenInviteCode(params){
    return get('/zhGenInviteCode', params);
  },

  /**
   * 领取邀请奖励
   * @param {*} params token, amount
   */
  icWebSiteClaim(params) {
    return get('/icWebSiteClaim', params);
  },
};

export default api;
