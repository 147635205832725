<template>
  <div class="image-container">
    <div class="image-player">
      <img class="image" :src="imageSource" alt="" />
    </div>
    <div class="image-close" @click="onClose()"></div>
  </div>
</template>

<script setup>
const { defineProps } = require('vue');

const props = defineProps({
  imageSource: String, // image source url
  onClose: Function // function to close the image player
});
</script>

<style lang="less" scoped>
.image-container {
  position: fixed;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  margin-top: 40px;
}

.image-player {
  width: 1344px;

  // height: auto;
  .image {
    width: 100%;
    height: auto;
  }
}

.image-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url(@/assets/images/close.png) no-repeat center;
  background-size: 50px 50px;
}
</style>