import api from "../api";
import { useStore } from "vuex";
import eventHub from "../eventHub";
import { ElMessage, ElMessageBox } from "element-plus";

export class solanaWallet {
  static _instance;
  solConnect = null;
  solanaWeb3 = null;
  adapter = null;
  rpcUrl = "https://api.devnet.solana.com";
  // rpcConnection = null;

  // TO__KEY = null;
  // TOKEN_PROGRAM_ID = null;
  // ATA_PUBKEY_KEY = null;

  store = null;

  constructor() {
    this.store = useStore();
  }

  static get instance() {
    if (this._instance == null) {
      this._instance = new solanaWallet();
    }
    return this._instance;
  }

  async init() {
    let isConnected = await this.isConnected();
    console.log("solana wallet is connected:", isConnected);
    if (window["SolanaConnect"]) {
      this.solConnect = new window["SolanaConnect"]();
    }
    // if (window["solanaWeb3"]) {
    //   this.solanaWeb3 = window["solanaWeb3"];
    //   await this.initSolanaWeb3();
    // }
    if (this.solConnect) {
      this.solConnect.onWalletChange((adapter) => {
        this.adapter = adapter;
        if (adapter) {
          this.loginHandler(
            adapter.publicKey.toString(),
            "Solana",
            adapter.name
          );
        }
      });

      this.solConnect.onVisibilityChange((isOpen) => {
        console.log("menu visible:", isOpen);
      });
    }
  }

  // async initSolanaWeb3() {
  //   this.rpcConnection = new this.solanaWeb3.Connection(this.rpcUrl);
  //   this.TO__KEY = new this.solanaWeb3.Key(
  //     "EZhhUANUMKsRhRMArczio1kLc9axefTUAh5xofGX35AK"
  //   );
  //   this.TOKEN_PROGRAM_ID = new this.solanaWeb3.Key(
  //     "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
  //   );
  //   this.ATA_PUBKEY_KEY = new this.solanaWeb3.Key(
  //     "9z7ZDeMgAHV4nvHAx8SKDw5wUnr1BjPbQiUkcX4rP375"
  //   );
  // }

  async isConnected() {
    if (this.adapter) {
      return true;
    } else {
      await this.connect();
      return false;
    }
  }

  async connect() {
    if (this.solConnect) {
      this.solConnect.openMenu();
    }
  }

  async disconnect() {
    if (this.adapter) {
      await this.adapter.disconnect();
      this.adapter = null;
    }
  }

  async getBalance() {
    if (this.isConnected()) {
      const balance = await this.rpcConnection.getTokenAccountBalance(
        this.ATA_PUBKEY_KEY
      );
      console.log(" =========balanace ========= ", balance.value.uiAmount);
    }
  }

  async loginHandler(accountAddress, chainType, walletType) {
    let params = {};
    params.mobile = accountAddress;
    params.pwd = "000000";
    params.type = 3;
    params.chainType = chainType;
    params.walletType = walletType;

    api.zhLogin(params).then((res) => {
      if (res.errCode === 0) {
        this.store.commit("setUserInfo", res.data);

        eventHub.$emit("home:login");
        this.store.commit("login");

        eventHub.$emit("update:display", false);
      } else {
        ElMessage.error(res.errMsg);
      }
    });
  }
}
