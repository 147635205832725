<template>
  <div class="home">
    <video loop="true" autoplay="true" width="1900" muted="true" src="../../assets/video/ic1101.mp4"></video>
    <div class="div container">
      <img src="../../assets/images/home/logo.png" ref="clockNode" alt="">
      <div class="d-flex align-items-center  justify-content-center btnbox row">
        <div class="bookNow myButton" style="margin-right: 24px;margin-left: 24px;" @click="onClickSpace">IC1101 Space
        </div>
        <div class="bookNow myButton" style="margin-right: 24px;margin-left: 24px;" @click="onClickJump">Bounty Hunter
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="row">
        <div class="col-4 d-flex align-items-center justify-content-center">
          <img src="@/assets/images/logo/Binance.png" alt="" height="66">
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center">
          <img src="@/assets/images/logo/EVM.png" alt="" height="66">
        </div>
        <div class="col-4 d-flex align-items-center justify-content-center">
          <img src="@/assets/images/logo/ICP.png" alt="" height="30">
        </div>
      </div>
      <div>© 2024 Abstracta Labs Pte. Ltd. All rights reserved.</div>
    </div>

  </div>
</template>

<script setup>
import {
  onBeforeUnmount,
  ref,
  inject,
  onBeforeMount,
  reactive,
  onUpdated,
  onMounted
} from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import md5 from "crypto-js/md5";
import axios from "axios";
import QS from "qs";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import discordInfo from "@/utils/discordInfo"
let hasLogin = ref(false);
let userInfo = reactive({});

let buttonShow = ref(false)

const router = useRouter();
const store = useStore();

const api = inject("$api");
const utils = inject("$utils");
const eventHub = inject("$eventHub");

let clockNode = ref(null);

onMounted(() => {
  updateScale();
})

const updateScale = () => {
  let scale = window.innerWidth / 1920;
  clockNode.value.style.transform = `scale(${scale})`;
}

const onClickSpace = () => {
  window.open('https://space.ic1101.world/')
}

const onClickJump = () => {
  router.push("./bountyHunter");
}


// axios discord数据
const axiosPos = async (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

const axiosGet = async (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};


const verifyDiscordCode = async () => {
  let code = localStorage.getItem("discordCode");
  if (!code) {
    code = utils.getQueryString("code");
    localStorage.setItem("discordCode", code);
  }
  if (code) {
    let params = {
      client_id: discordInfo.client_id,
      client_secret: discordInfo.client_secret,
      grant_type: "authorization_code",
      code,
      redirect_uri: discordInfo.redirect_url,
      scope: "identify%20guilds",
    };
    try {
      var url = window.location.href;
      url = url.replace(/(\?|#)[^'"]*/, ""); //去除参数
      window.history.pushState({}, 0, url);
      let joinDiscord = localStorage.getItem("joinDiscord") == "true" ? true : false;
      if (hasLogin.value == false) {
        joinDiscord = false;
      }
      let taskId = localStorage.getItem("taskId");
      console.log("joinDiscord", joinDiscord);
      console.log("taskId", taskId);

      let tokenData = await axiosPos(
        "https://discord.com/api/oauth2/token",
        params
      );

      if (tokenData.access_token) {
        let headers = {
          authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        }

        let myGuilds = await axiosGet(
          "https://discord.com/api/users/@me/guilds",
          headers
        );
        if (joinDiscord == true) {
          let ic1101GuildsId = "973881922910564383";
          let hasIC1101Guild = false;
          for (let i = 0; i < myGuilds.length; i++) {
            let item = myGuilds[i];
            if (item.id == ic1101GuildsId) {
              hasIC1101Guild = true;
            }
          }
          if (hasIC1101Guild == true) {
            taskEvent(taskId)
          } else {
            // 加入discord
            window.open("https://discord.gg/ic1101");
            localStorage.removeItem("discordCode");
            router.push("./airDrop");
          }
        } else {
          let meConfig = await axiosGet(
            "https://discord.com/api/users/@me",
            headers
          );
          loginHandler(meConfig.id)
        }
      } else {
        localStorage.removeItem("discordCode");
      }
    } catch (error) {
      console.log("error", error);
      localStorage.removeItem("discordCode");
    }
  }
};

const taskEvent = (taskId) => {
  let token = userInfo.token;
  let params = {
    token,
    taskId,
    tabId: 20,
  };

  api.zhTaskEvent(params).then((res) => {
    if (res.errCode == 0) {
      localStorage.removeItem("joinDiscord");
      localStorage.removeItem("taskId");
      localStorage.removeItem("discordCode");
      router.push("./airDrop");
    }
  });
}

const loginHandler = (accountAddress) => {
  let params = {};
  params.mobile = accountAddress + "";
  params.pwd = "000000";
  params.type = 3;
  params.chainType = "Discord";
  params.walletType = "Discord";

  api.zhLogin(params).then((res) => {
    if (res.errCode === 0) {
      let routePath = localStorage.getItem("routePath");
      localStorage.removeItem("discordCode");
      localStorage.removeItem("routePath");
      store.commit("setUserInfo", res.data);
      eventHub.$emit("home:login");
      store.commit("login");
      eventHub.$emit("update:display", false);
      router.push('.' + routePath);
    } else {
      ElMessage.error(res.errMsg);
    }
  })
}


function getQueryParam(params) {
  var res = new RegExp("(?:&|/?)" + params + "=([^&$]+)").exec(window.location.href);
  return res ? res[1] : '';
}
const verifyInvite = () => {
  let invite = getQueryParam('invite')
  store.commit("setInvite", invite);
}

onBeforeMount(() => {

  hasLogin.value = store.state.hasLogin == "true" ? true : false;
  store.commit("getUserInfo");
  userInfo = store.state.userInfo;
  console.log("userInfo", userInfo);

  verifyInvite();
  // 验证discord
  verifyDiscordCode();
});
onBeforeUnmount(() => { });
onUpdated(() => {
  hasLogin.value = store.state.hasLogin == "true" ? true : false;
  userInfo = store.state.userInfo;
});
</script>

<style lang="less" scoped>
.home {
  // width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .div {
    position: fixed;
    top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .box {
      margin-top: 100px;
      margin-left: -100%;
    }

    .btnbox {
      position: relative;
    }

    .buttonDiv {
      position: absolute;
      display: flex;
      left: calc(-50% + 0px);
      top: 80px;
    }

    .bookNow {
      margin-top: 48px;
    }
  }

  .foot {
    position: fixed;
    bottom: 24px;
    font-size: 13px;
    color: #757474;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.myButton {
  width: 306px;
  height: 58px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 306px 58px;
  line-height: 58px;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  font-family: "Bold";
}

.myButton:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 306px 58px;
}

.dialog {
  .dialog-footer {
    display: flex;
    justify-content: center;
  }

  .marginleft {
    margin-left: 81px;
  }
}

.dialog /deep/ .el-dialog {
  background: rgba(0, 0, 0, 0.76);
}

.dialog /deep/ .el-dialog__body {
  color: #fff;
}

.dialog /deep/ .el-dialog__title {
  color: #fff;
}

// .btnbox /deep/ .tooltip-title{
//   height: 40px;
//   line-height: 40px;
//   padding: 0px 8px
// }</style>

<!-- 
tooltip  
名称	说明
title	string类型，tooltip的内容
trigger	hover 或者 click ， tooltip的触发方式，默认值是hover
visible	boolean类型，可以通过父组件来自定义控制trigger的消失或隐藏
position	tooltip出现的位置，默认值是 topLeft
background	背景色，如果有背景色设置，字体默认是白色
width	tooltip的宽度
————————————————
版权声明：本文为CSDN博主「可缺不可滥」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
原文链接：https://blog.csdn.net/glorydx/article/details/125357642
http://dxui.cn/
 -->
