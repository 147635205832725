<template>
  <div class="home">
    <div class="container">
      <div class="d-flex align-items-center flex-column" v-if="!hasLogin">
        <div :class="step != 4 ? 'bg' : ''">
          <div class="d-flex align-items-center flex-column" style="margin-top: 260px">
            <div class="d-flex">
              <div class="d-flex">
                <div style="font-size: 130px; width: 200px; text-align: right">
                  0{{ step }}
                </div>
                <img src="@/assets/images/home/line1.png" width="95" height="53" style="margin-top: 90px" />
              </div>
              <div class="d-flex flex-column align-items-end" style="margin-top: 90px; margin-left: 450px">
                <img src="@/assets/images/home/line2.png" width="202" height="12" />
                <!-- <span>{{ stepTitle[step - 1] }}</span> -->
              </div>
            </div>
            <div class="d-flex flex-column" style="margin-top: 150px; height: 110px">
              <div v-if="discordAuth">
                <div class="myButton" style="margin-bottom: 24px" @click="onClickJoinDiscord">
                  1. Join Our Discord
                </div>
                <div class="myButton" @click="onClickVerifyDiscord">2. Verify</div>
              </div>
              <div v-else>
                <div style="height: 40px; margin-bottom: 24px"></div>
                <div class="myButton" @click="onClickHandler">
                  {{ stepTitle[step - 1] }}
                </div>
              </div>
            </div>
            <div class="d-flex step-bg" style="margin-top: 40px">
              <div class="div">
                <div class="step-bar"></div>
              </div>
              <div class="line" v-if="step >= 2"></div>
              <div class="div" v-if="step >= 2">
                <div class="step-bar"></div>
              </div>
              <div class="line" v-if="step >= 3"></div>
              <div class="div" v-if="step >= 3">
                <div class="step-bar"></div>
              </div>
              <div class="line" v-if="step == 4"></div>
              <div class="div" v-if="step == 4">
                <div class="step-bar"></div>
              </div>
            </div>
            <div class="formBox" v-if="step == 4">
              <el-form ref="ruleFormRef" :model="RegisterRuleForm" :rules="RegisterRules" class="inputBox" status-icon>
                <!-- 注册 -->
                <el-form-item label="" prop="username" style="width: 100%">
                  <el-input v-model="RegisterRuleForm.username" placeholder="Username" />
                </el-form-item>
                <el-form-item label="" prop="password" style="width: 100%">
                  <el-input v-model="RegisterRuleForm.password" type="password" placeholder="Password" show-password />
                </el-form-item>
                <el-form-item label="" prop="confirmPassword" style="width: 100%">
                  <el-input v-model="RegisterRuleForm.confirmPassword" placeholder="Confirm Password" type="password"
                    show-password />
                </el-form-item>
                <el-form-item label="" prop="email" style="width: 100%">
                  <el-input :class="!codeType ? 'emailbox1' : 'emailbox2'" v-model="RegisterRuleForm.email"
                    placeholder="Email" @input="onChangeFormEmail">
                    <template #append>
                      <el-button @click="onClickSendCode">
                        {{ codeTxt }}
                      </el-button>
                    </template>
                  </el-input>
                </el-form-item>
                <el-form-item label="" prop="verifyCode" style="width: 100%">
                  <el-input v-model="RegisterRuleForm.verifyCode" placeholder="Verify Code" />
                </el-form-item>
                <el-form-item label="" prop="invitationCode" style="width: 100%">
                  <el-input v-model="RegisterRuleForm.invitationCode" placeholder="Invitation Code(Optional)" />
                </el-form-item>
                <el-form-item label="" prop="pick" style="width: 100%">
                  <div class="certifyBox">
                    <el-checkbox v-model="RegisterRuleForm.pick"></el-checkbox>
                    <p style="margin-left: 5px">
                      I certify that I am at least 18years old and that I agree
                      to the
                      <a href="">Terms and Policies</a>
                      and
                      <a href="">Privacy Policy.</a>
                    </p>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex" v-else>
        <Account></Account>
      </div>
    </div>
  </div>
  <WalletConnect :display="showDialog"></WalletConnect>
</template>

<script setup>
import {
  onBeforeUnmount,
  reactive,
  ref,
  inject,
  onBeforeMount,
  onUpdated,
} from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import axios from "axios";
import WalletConnect from "@/components/PC/WalletConnect.vue";
import Account from "./Account.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import md5 from "crypto-js/md5";

import discordInfo from "@/utils/discordInfo";

const router = useRouter();
const ruleFormRef = ref(null);
const api = inject("$api");
const utils = inject("$utils");
const eventHub = inject("$eventHub");
const store = useStore();

let showDialog = ref(false);
let hasLogin = ref(false);
let discordAuth = ref(false);
let userInfo = reactive({});
let tokenData = reactive({});

let meConfig = ref(null)


let step = ref(1);
let stepTitle = reactive([
  "Connect Wallet",
  "Connect Discord",
  "Connect Twitter",
  "Register",
]);

let codeTxt = ref("Send");
let codeType = ref(false);


const validatePass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Please input the password again"));
  } else if (value !== RegisterRuleForm.password) {
    callback(new Error("Two inputs don't match!"));
  } else {
    callback();
  }
};

const RegisterRuleForm = reactive({
  invitationCode: "",
  username: "",
  password: "",
  confirmPassword: "",
  email: "",
  verifyCode: "",
  pick: false,
});

const RegisterRules = reactive({
  username: [
    { required: true, message: "Please input the username", trigger: "blur" },
  ],
  password: [
    { required: true, message: "Please input the password", trigger: "blur" },
    {
      min: 6,
      message: "Password must not be less than 6 digits",
      trigger: "blur",
    }, //密码不得少于六位
  ],
  confirmPassword: [{ validator: validatePass, trigger: "blur" }],
  email: [
    { required: true, message: "Please input the email", trigger: "blur" },
    {
      type: "email",
      message: "Please input correct email address",
      trigger: ["blur", "change"],
    },
  ],
  verifyCode: [
    {
      required: true,
      message: "Please input the Verify Code",
      trigger: "blur",
    },
    {
      // min: 6,
      // max: 6,
      message: "Please input 6-digit verification code",
      trigger: "blur",
    }, //六位验证码
  ],
  // invitationCode: [{
  //   required: true,
  //   message: "Please input the Invitation Code",
  //   trigger: "blur",
  // },],
  pick: [{ required: true, message: "Please pick a date", trigger: "change" }],
});

const onChangeFormEmail = async () => {
  let form;
  form = RegisterRuleForm;
  try {
    const { email } = form;
    let emailRegExp = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    var ok = emailRegExp.test(email); //验证是否符合要求
    if (ok) {
      codeType.value = true;
    } else {
      codeType.value = false;
    }
  } catch (error) {
    console.log("error:", error);
  }
};

let timeData = ref(null);
const setTimeData = (cm) => {
  let cmt;
  if (cm < 10) {
    cmt = "0" + cm;
  } else {
    cmt = cm + "";
  }
  codeTxt.value = cm + "";
  if (cm > 1) {
    timeData.value = setTimeout(() => {
      cm--;
      setTimeData(cm);
    }, 1000);
  } else {
    codeType.value = true;
    codeTxt.value = "SEND";
  }
};

const onClickSendCode = () => {
  if (codeType.value == false) {
    return;
  }
  let form = RegisterRuleForm;

  codeType.value = false;
  setTimeData(60);

  const { email } = form;
  let params = {
    email,
  };
  api.get_mail_code(params).then((res) => {
    if (res.errCode == 0) {
      ElMessage({
        message: "VERIFY CODE SEND Success",
        type: "success",
      });
    } else {
      ElMessage.error(res.errMsg);
    }
  });
};

const onClickHandler = () => {
  switch (step.value) {
    case 1:
      showDialog.value = true;
      break;
    case 2:
      connectDiscord();
      break;
    case 3:
      connectTwitter();
      break;
    case 4:
      bindAccountWallet();
      break;
  }
};

const bindAccountWallet = async () => {
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      try {
        const { username, password, email, verifyCode, invitationCode, pick } =
          RegisterRuleForm;
        if (pick == false) {
          ElMessage.error("Please read and confirm the agreement carefully");
          return;
        }
        let token = userInfo.token;
        let params = {
          token,
          account: username,
          pwd: password,
          email,
          emailCode: verifyCode,
          srcInviteCode: invitationCode,
          discord: meConfig.value.id
        };
        api.bindAccountWithWallet(params).then((res) => {
          if (res.errCode == 0) {
            store.commit("login");
            store.commit("setUserInfo", res.data);
            hasLogin.value = true;
            store.commit("setInvite", null);
            step.value = 1;
            store.commit("setStep", step.value);
          } else {
            ElMessage.error(res.errMsg);
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    }
  });
};

const connectTwitter = () => {
  window.open("https://twitter.com/IC1101World");
  step.value = step.value + 1;
  store.commit("setStep", step.value);
};



const axiosGet = async (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

const connectDiscord = async () => {
  window.location.href =  discordInfo.href
};

const onClickJoinDiscord = () => {
  // window.open("https://discord.gg/ic1101world");
  window.open("https://discord.gg/ic1101");
  // 
};
const onClickVerifyDiscord = async () => {
  let ic1101GuildsId = "973881922910564383";
  let headers = {
    authorization: `${tokenData.token_type} ${tokenData.access_token}`,
  };
  let myGuilds = await axiosGet(
    "https://discord.com/api/users/@me/guilds",
    headers
  );

  meConfig.value = await axiosGet(
    "https://discord.com/api/users/@me",
    headers
  );
  let hasIC1101Guild = false;
  for (let i = 0; i < myGuilds.length; i++) {
    let item = myGuilds[i];
    if (item.id == ic1101GuildsId) {
      hasIC1101Guild = true;
    }
  }
  if (hasIC1101Guild == true) {
    step.value = 3;
    store.commit("setStep", 3);
    discordAuth.value = false;
    store.commit("setDiscordAuth", false);
    
  } else {
    ElMessage.error("Please check join our discord");
  }
};


onBeforeMount(() => {
  hasLogin.value = store.state.hasLogin == "true" ? true : false;
  store.commit("getUserInfo");
  userInfo = store.state.userInfo;
  console.log("userInfo", userInfo);

  RegisterRuleForm.invitationCode = store.state.invite;

  //更新登录窗口状态
  eventHub.$on("update:display", (bool) => {
    if (bool == false) {
      showDialog.value = bool;
    }
  });
  // 下一步
  eventHub.$on("home:nextStep", () => {
    step.value = step.value + 1;
    store.commit("setStep", step.value);
  });

  //登录成功
  eventHub.$on("home:login", () => {
    hasLogin.value = true;
  });

  //登录成功
  eventHub.$on("home:logout", () => {
    hasLogin.value = false;
  });

  step.value = 2//store.state.step;
  tokenData = store.state.tokenData;
  discordAuth.value = store.state.discordAuth;
});


onBeforeUnmount(() => {
  // eventHub.$off("update:display");
  eventHub.$off("home:nextStep");
});
onUpdated(() => {
  hasLogin.value = store.state.hasLogin == "true" ? true : false;
  userInfo = store.state.userInfo;
});
</script>

<style lang="less" scoped>
.home {
  width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(@/assets/images/home/img1_di.png) no-repeat center;
  background-size: 100% 100%;
  padding-top: 74px;
}

.container {
  position: relative;
}

.bg {
  width: 576px;
  height: 644px;
  background: url(@/assets/images/home/robot.png) no-repeat center;
  background-size: 576px 644px;
}


.step-bg {
  width: 358px;
  height: 28px;
  background: url(@/assets/images/home/progressbar01.png) no-repeat center;
  background-size: 358px 28px;
  display: flex;
  align-items: center;

  .div {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-bar {
    width: 12px;
    height: 12px;
    background: url(@/assets/images/home/progressbar02.png) no-repeat center;
    background-size: 12px 12px;
  }

  .line {
    width: 82px;
    height: 2px;
    background-color: #1eadfb;
  }
}

.formBox {
  position: absolute;
  top: 80px;
}

.inputBox {
  padding: 0 44px;
  display: flex;
  flex-direction: column;
  width: 376px;
  align-items: flex-end;
}

.inputBox /deep/ .el-input {
  margin-top: 6px;
}

.emailbox1,
.emailbox2 {
  display: flex;
  align-items: center;
}

.emailbox1 /deep/ .el-input-group__append {
  color: #fff;
  background-color: #999;
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0 0 1px #999 inset;
}

.emailbox2 /deep/ .el-input-group__append {
  color: #fff;
  background-color: #0cacff;
  height: 40px;
  line-height: 40px;
  box-shadow: 0 0 0 1px #0cacff inset;
}

.certifyBox {
  margin-left: -18px;
  display: flex;

  a {
    color: #0cacff;
  }
}

.myButton {
  width: 208px;
  height: 40px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 208px 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  right: 0px;
}

.myButton:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 208px 40px;
}
</style>
