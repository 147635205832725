<template>
  <div class="dialog" v-if="display">
    <el-dialog v-model="display" title="Connect to a wallet" width="380px" :before-close="handleClose">
      <div class="dialog-content" v-if="!isConnect">
        <div class="wallet-box" @click="onClickEVM">
          EVM
          <img src="@/assets/images/Eth.webp" alt="" width="24" />
        </div>
        <div class="wallet-box" @click="onClickSolana">
          Solana
          <img src="@/assets/images/Solana.png" alt="" width="24" />
        </div>
        <div class="wallet-box" @click="onClickBitcoin">
          Bitcoin(Taproot)
          <img src="@/assets/images/BTC.webp" alt="" width="24" />
        </div>
        <div class="wallet-box" @click="onClickDiscord">
          Discord
          <img src="@/assets/images/discord2.png" alt="" width="24" />
        </div>
      </div>
      <div class="dialog-content" v-else>
        <div class="wallet-box" v-if="chooseWallet === 'EVM'" @click="onClickMM">
          MetaMask
          <img src="@/assets/images/foxIcon.png" alt="" width="24" />
        </div>
        <div class="wallet-box" @click="onClickOKX">
          OKX wallet
          <img src="@/assets/images/okx.png" alt="" width="24" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  inject,
  onBeforeMount,
  toRefs,
} from "vue";

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox } from "element-plus";
import { okxApi } from "@/utils/okxwallet/okxApi";
import { solanaWallet } from "@/utils/solanawallet/solanaWallet";
import discordInfo from "@/utils/discordInfo";

const router = useRouter();
const api = inject("$api");
const eventHub = inject("$eventHub");
const ethereum = inject("$ethereum");
const store = useStore();

let props = defineProps({
  display: {
    type: Boolean,
  },
});
let { display } = toRefs(props);

let isConnect = ref(false);
let chooseWallet = ref("EVM");

const handleClose = () => {
  isConnect.value = false;
  eventHub.$emit("update:display", false);
};

const onClickEVM = () => {
  chooseWallet.value = "EVM";
  isConnect.value = true;
};


const onClickSolana = () => {
  solanaWallet.instance.connect();
  isConnect.value = false;
  eventHub.$emit("update:display", false);
};

const onClickBitcoin = () => {
  chooseWallet.value = "Bitcoin";
  isConnect.value = true;
};

const onClickDiscord = () => {
  localStorage.setItem("routePath", router.currentRoute.value.path)
  window.location.href = discordInfo.href;
};

const onClickMM = () => {
  ethereum.connectMM((accountAddress) => {
    if (accountAddress == undefined) {
      return;
    }
    loginHandler(accountAddress, chooseWallet.value, "MM");
  });
}


const onClickOKX = () => {
  okxApi.instance.init(chooseWallet.value);
  okxApi.instance.connect((accountAddress) => {
    if (accountAddress == undefined) {
      return;
    }
    loginHandler(accountAddress, chooseWallet.value, "OKX");
  });
}

const loginHandler = (accountAddress, chainType, walletType) => {
  console.log("loginHandler", accountAddress, chainType, walletType);
  let params = {};
  params.mobile = accountAddress;
  params.pwd = "000000";
  params.type = 3;
  params.chainType = chainType;
  params.walletType = walletType;

  api.zhLogin(params).then((res) => {
    if (res.errCode === 0) {
      isConnect.value = false;
      store.commit("setUserInfo", res.data);
      eventHub.$emit("home:login");
      store.commit("login");
      eventHub.$emit("update:display", false);
    } else {
      ElMessage.error(res.errMsg);
    }
  })
}


</script>

<style lang="less" scoped>
.dialog-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.wallet-box {
  height: 39px;
  width: 222px;
  color: #f0b80b;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 222px 39px;
  padding: 0 24px;
  margin-bottom: 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
}

.wallet-box:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 222px 39px;
}

.dialog /deep/ .el-dialog {
  background: rgba(0, 0, 0, 0.76);
}

.dialog /deep/ .el-dialog__body {
  color: #fff;
}

.dialog /deep/ .el-dialog__title {
  color: #fff;
}
</style>
