import { OkxBitcoinApi } from "./OkxBitcoinApi";
import { OkxSolanaApi } from "./OkxSolanaApi";
import { OkxEVMApi } from "./OkxEVMApi";

const ua = navigator.userAgent;
const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
const isAndroid = /android|XiaoMi|MiuiBrowser/i.test(ua);
const isMobile = isIOS || isAndroid;
const isOKApp = /OKApp/i.test(ua);

const Provider = {
  Bitcoin: "Bitcoin",
  Solana: "Solana",
  EVM: "EVM",
};

export class okxApi {
  curProvider = Provider.Bitcoin;
  apiScript = null;
  okxwallet = window["okxwallet"];

  address = "";
  static _instance = null;
  static get instance() {
    if (this._instance == null) {
      this._instance = new okxApi();
    }
    return this._instance;
  }

  init(provider, cb) {
    if (provider) {
      this.curProvider = provider;
    }
    this.setProviderApi(cb);
  }

  setProviderApi(cb) {
    switch (this.curProvider) {
      case Provider.Bitcoin:
        this.apiScript = OkxBitcoinApi.instance;
        break;
      case Provider.Solana:
        this.apiScript = OkxSolanaApi.instance;
        break;
      case Provider.EVM:
        this.apiScript = OkxEVMApi.instance;
        break;
    }
    this.apiScript.init(cb);
  }

  async connect(cb) {
    if (this.okxwallet !== "undefined" && this.okxwallet !== undefined) {
      if (this.apiScript) {
        this.apiScript.connect((address) => {
          this.address = address;
          cb(address);
        });
      }
    } else {
      if (isMobile && !isOKApp) {
        const encodedUrl =
          "https://www.okx.com/download?deeplink=" +
          encodeURIComponent(
            "okx://wallet/dapp/url?dappUrl=" +
              encodeURIComponent("http://space.ic1101.world/")
          );
        window.location.href = encodedUrl;
      } else {
        window.location.href =
          "https://chrome.google.com/webstore/detail/okx-wallet/mcohilncbfahbmgdjkbpemcciiolgcge";
      }
    }
  }

  async sign(cb) {
    if (this.apiScript) {
      this.apiScript.sign(this.address, cb);
    }
  }
}
