<template>
  <div class="div">
    <div class="container">
      <div class="d-flex flex-column">
        <div class="d-flex">
          <img src="@/assets/images/account/account.png" width="80" height="80" />
          <div class="d-flex flex-column justify-content-center" style="margin-left: 24px">
            <span style="font-size: 28px">{{ userInfo.account }}</span>
            <div class="d-flex">
              <div class="address-div">address</div>
              <div style="margin-left: 16px; line-height: 24px">
                {{ formaddress(address) }}
              </div>
              <img src="@/assets/images/account/copy.png" width="24" height="24" @click="onClickCopy(address)"
                style="margin-left: 16px; cursor: pointer" />
            </div>
          </div>
        </div>
      </div>

      <div class="b-example-divider"></div>

      <div class="d-flex flex-column" v-if="tokenList.length > 0">
        <div class="d-flex justify-content-between box">
          <div class="title">Total Assets</div>
          <div class="button" v-if="tokenList.length > 6">
            <img src="@/assets/images/account/collapse1.png" @click="onClickCollapseToken(false)" />
            <img src="@/assets/images/account/collapse2.png" @click="onClickCollapseToken(true)" />
          </div>
        </div>
        <div class="token-box" :style="cardHeightStyleToken">
          <el-row :gutter="20">
            <el-col :span="4" v-for="(item, index) in tokenList">
              <div class="imgDiv">
                <img class="imgdi" :src="require(`@/assets/images/account/${item.url}.png`)" />
                <div class="textDiv2">
                  <span>{{ item.coin }}</span>
                  <span>{{ item.unit }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="tokenList.length > 6" class="moreBtnBox">
          <div class="exploreMore" @click="onClickMoreToken">Explore more</div>
        </div>
      </div>

      <div class="b-example-divider"></div>

      <div class="d-flex flex-column" v-if="collectiblesList.length > 0">
        <div class="d-flex justify-content-between box">
          <div class="title">Collectibles</div>
          <div class="button" v-if="collectiblesList.length > 4">
            <img src="@/assets/images/account/collapse1.png" @click="onClickCollapse3(false)" />
            <img src="@/assets/images/account/collapse2.png" @click="onClickCollapse3(true)" />
          </div>
        </div>
        <div class="collect-box" :style="cardHeightStyleNFT">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(item, index) in collectiblesList">
              <div class="itemDi cursor" @click="gotoOpenSea(item)">
                <div class="imageDi">
                  <img :src="item.image" class="image" />
                </div>
                <div class="info">
                  <span>{{ item.name }}</span>
                  <span>#{{ item.tokenId }}</span>
                  <span>{{ formaddress(item.address) }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-if="collectiblesList.length > 4" class="moreBtnBox">
          <div class="exploreMore" @click="onClickMore3">Explore more</div>
        </div>
      </div>

      <div class="b-example-divider"></div>
      <div class="d-flex align-items-center justify-content-between" style="font-size: 20px;">
        <div class="d-flex align-items-center">
          <div>Invitation Code:</div>
          <div style="margin-left: 16px;color: aquamarine;">{{ userInfo.inviteCode }}</div>
          <img src="@/assets/images/account/copy.png" width="24" height="24" @click="onClickCopy(userInfo.inviteCode)"
            style="margin-left: 16px; cursor: pointer" />
        </div>
        <div class="d-flex ">
          <div class="exploreMore" style="margin-top: 0px;" @click="onClickHome">Home</div>
          <div class="exploreMore" style="margin-top: 0px; margin-left: 24px;" @click="onClickShare">Share</div>
        </div>
      </div>
      <div class="row align-items-center justify-content-between py-5 rounded-3 border-div px-4"
        style="margin-top: 16px;">
        <div class="col-10 col-sm-8 col-lg-6" style="height: 400px; width: 100%">
          <table class="table" style="color: white">
            <thead>
              <tr>
                <th>Date</th>
                <th>Event</th>
                <th>Coin</th>
              </tr>
            </thead>
            <tbody v-for="(item, index) in tableData">
              <tr>
                <td>{{ item.createTime }}</td>
                <td>{{ item.eventDesc }}</td>
                <td><img src="@/assets/images/account/IST.png" width="20" /> {{ item.dstPoint - item.srcPoint }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="pageTotalSize > 0" class="d-flex align-items-center justify-content-end" style="margin-top: 24px;">
        <NLYpagination ref="pagination" :total="total" :size="pageSize" :limit="5" :currentPage="curIdx"
          :firstFunction="firstFunction" :prevFunction="prevFunction" :currentFunction="currentFunction"
          :nextFunction="nextFunction" :lastFunction="lastFunction" :sizeFunction="sizeFunction"
          @getCurrentPage="getCurrentPage" />
      </div>
      <div class="d-flex align-items-center justify-content-center">

      </div>
      <div class="b-example-divider"></div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeUnmount,
  reactive,
  ref,
  inject,
  onBeforeMount,
  onUpdated,
} from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import md5 from "crypto-js/md5";
import NLYpagination from "@/components/PC/NLYpagination";

const router = useRouter();
const store = useStore();
const api = inject("$api");
const utils = inject("$utils");
const eventHub = inject("$eventHub");

let curIdx = ref(1);
let pageTotalSize = ref(0);
let total = ref(0);
let pageSize = ref(10)

let userInfo = reactive({});
let hasLogin = ref(false);

let address = ref("0x0000000000000000000000000000000000000000");


let cardHeightStyleToken = reactive({
  height: "110px",
});

let cardHeightStyleNFT = reactive({
  height: "357px",
});

const tableData = reactive([]);

let tokenList = reactive([
  {
    url: "IST",
    coin: 0,
    unit: "IWT",
  },
]);

let collectiblesList = reactive([]);

const formaddress = (address) => {
  return utils.formAddress(address);
};

const onClickCopy = (string) => {
  var input = document.createElement("input"); // 直接构建input
  input.value = string; // 设置内容
  console.log(input.value);

  document.body.appendChild(input); // 添加临时实例
  input.select(); // 选择实例内容
  document.execCommand("Copy"); // 执行复制
  document.body.removeChild(input); // 删除临时实例

  ElMessage({
    message: "Invite Code Copied",
    type: "success",
  });
};

const onClickCollapseToken = (bool) => {
  if (bool == false) {
    cardHeightStyleToken.height = "110px";
  } else {
    cardHeightStyleToken.height = "100%";
  }
};

const onClickMoreToken = () => {
  cardHeightStyleToken.height = "100%";
};

const onClickCollapse3 = (bool) => {
  if (bool == false) {
    cardHeightStyleNFT.height = "357px";
  } else {
    cardHeightStyleNFT.height = "100%";
  }
};

const onClickMore3 = () => {
  cardHeightStyleNFT.height = "100%";
};

const gotoOpenSea = () => { };

const initUserInfo = () => {
  let token = userInfo.token;
  let params = {
    loginType: 3,
    token,
  };
  api.accountInfo(params).then((res) => {
    if (res.errCode == 0) {
      store.commit("setUserInfo", res.data);
      try {
        address.value = userInfo.authWallets[0].address
        for (let i = 0; i < tokenList.length; i++) {
          let item = tokenList[i];
          if (item.unit === 'IWT') {
            item.coin = userInfo.score
          }
        }
      } catch (error) {
      }
      accountScoreDetails();
    }
  });
};

const accountScoreDetails = () => {
  let token = userInfo.token;
  let params = {
    pageSize: pageSize.value,
    curIdx: curIdx.value - 1,
    token,
  };
  api.accountScoreDetails(params).then((res) => {
    if (res.errCode == 0) {
      pageTotalSize.value = Math.floor((res.totalSize - 1) / 10 + 1);
      total.value = res.totalSize;
      tableData.length = 0;
      for (let i = 0; i < res.data.length; i++) {
        tableData.push(res.data[i])
      }
    }
  });
}

const firstFunction = () => {
  console.log(
    `跳到第一页:`
  );
}
const prevFunction = () => {
  console.log(
    `跳到上一页:`
  );
}
const currentFunction = () => {
  console.log(
    `点击跳转到当前点击页码页面: `
  );
}
const nextFunction = () => {
  console.log(
    `跳到下一页: `
  );
}
const lastFunction = () => {
  console.log(
    `跳到最后一页:`
  );
}
const sizeFunction = () => {
  console.log("修改每页数量");
}
const getCurrentPage = (data) => {
  curIdx.value = data;
  accountScoreDetails();
}

const onClickShare = () => {
  let shareUrl = `https://ic1101.world/?invite=${userInfo.inviteCode}`
  var url = `https://twitter.com/intent/tweet?url=${shareUrl}&via=IC1101World&text=${"IC1101, Social attribute metaverse!"}`;
  window.open(url);
}

const onClickHome = ()=>{
  router.push("./");
}

onBeforeMount(() => {
  hasLogin.value = store.state.hasLogin == "true" ? true : false;
  if (hasLogin.value == true) {
    store.commit("getUserInfo");
    userInfo = store.state.userInfo;
    initUserInfo();
  } else {
    router.push("./");
  }
});
</script>

<style lang="less" scoped>
.div {
  width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  background-size: 100% 100%;
}

.address-div {
  padding: 0 7px;
  align-items: center;
  line-height: 24px;
  background-color: #0cacff;
  font-size: 12px;
  border-radius: 12px;
}

.box {
  .title {
    font-size: 20px;
  }

  .button {
    display: flex;
    align-items: center;

    img:nth-of-type(1) {
      cursor: pointer;
    }

    img:nth-of-type(2) {
      margin-left: 13px;
      cursor: pointer;
    }
  }
}

.token-box {
  overflow: hidden;

  .imgDiv {
    margin-top: 31px;
    position: relative;
    width: 70px;
    height: 70px;

    .imgdi {
      position: absolute;
      width: 70px;
      height: 70px;
    }

    .imgType {
      position: absolute;
      width: 29px;
      height: 29px;
      right: 0;
      bottom: 0;
    }

    .textDiv {
      font-size: 15px;
      width: 140px;
      overflow: hidden;
      position: absolute;
      left: 80px;
      top: calc(50% - 9px);
    }

    .addDiv {
      font-size: 40px;
      overflow: hidden;
      position: absolute;
      left: calc(50% - 12px);
      top: calc(50% - 24px);
    }

    .textDiv2 {
      width: 140px;
      overflow: hidden;
      position: absolute;
      left: 80px;
      top: calc(50% - 21px);
      display: flex;
      flex-direction: column;

      span:nth-of-type(1) {
        font-size: 15px;
      }

      span:nth-of-type(2) {
        font-size: 15px;
        margin-top: 6px;
      }
    }
  }
}

.collect-box {
  overflow: hidden;

  .itemDi {
    margin-top: 33px;
    width: 240px;
    height: 324px;
    border-radius: 10px;
    background: url(@/assets/images/account/collectBg.png) no-repeat center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imageDi {
      display: flex;
      margin-top: 11px;
      background-color: rgba(36, 36, 36, 0.72);
      border-radius: 10px;
      width: 220px;
      height: 220px;
      align-items: flex-end;
      justify-content: center;
      overflow: hidden;
    }

    .image {
      width: 180px;
      height: 180px;
    }

    @keyframes scale {
      0% {
        transform: scale(1);
      }

      100% {
        transform: scale(1.2);
      }
    }

    .image:hover {
      animation: scale 0.5s;
      animation-fill-mode: forwards;
      -webkit-animation-fill-mode: forwards;
    }

    .info {
      width: 220px;
      margin-top: 14px;
      display: flex;
      flex-direction: column;

      span:nth-of-type(1) {
        font-size: 14px;
        color: #fff;
      }

      span:nth-of-type(2) {
        margin-top: 8px;
        font-size: 16px;
        color: #000;
      }

      span:nth-of-type(3) {
        margin-top: 8px;
        font-size: 13px;
        color: #fff;
      }
    }
  }
}

.moreBtnBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exploreMore {
  display: flex;
  width: 208px;
  height: 40px;
  margin-top: 49px;
  line-height: 40px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  justify-content: center;
  cursor: pointer;
}

.exploreMore:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
}

.border-div {
  border: 1px solid bisque;
}
</style>
