<template>
  <header class="header">
    <nav class="navbar navbar-expand-md fixed-top top_nav">
      <div class="container-fluid" style="margin: 0 40px;">
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
          <img src="@/assets/images/ic-logo.png" width="132" height="34" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="nav nav-pills header_nav navbar-nav me-auto mb-2 mb-md-0">
            <li class="link nav-item" :class="item.clicked ? 'clicked' : ''" v-for="(item, index) in headerList"
              style="padding: 0 10px;">
              <div v-if="item.type === 'dropdown'" class="dropdown">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
                style="color: tomato;">
                {{ item.title }}
              </a>
              <ul class="dropdown-menu menu-list" style="background-color: black;">
                <li v-for="(menu, idx) in item.menuList" :key="idx" href="#" class="dropdown-item"
                  style="background-color: black;">
                  <router-link :to="{ name: menu.name }" :key="idx">
                    <span class="nav-link">{{ menu.title }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
        </div>
        <div v-else-if="item.type === 'link'">
          <a href="#" class="nav-link" style="color: white;" @click="onClickLink(item)">{{ item.title }}</a>
        </div>
        <div v-else>
          <router-link :to="{ name: item.name }" :key="index">
            <span class="nav-link">{{ item.title }}</span>
          </router-link>
        </div>
        </li>
        <li class="account nav-item">
          <a href="https://www.youtube.com/channel/UCMXgx_ontMPlOVHFuhtj-Lg" target="_blank">
            <img src="@/assets/images/youtube.png" height="25" />
          </a>
        </li>
        <li class="account nav-item">
          <a href="https://x.com/IC1101World" target="_blank">
            <img src="@/assets/images/twitter.png" height="34" />
          </a>
        </li>
        <li class="account nav-item">
          <a href="https://discord.gg/ic1101" target="_blank">
            <img src="@/assets/images/discord.png" height="34" />
          </a>
        </li>
        <li class="account nav-item">
          <a href="https://medium.com/@IC1101World" target="_blank">
            <img src="@/assets/images/medium.png" height="20" />
          </a>
        </li>

        <li class="account nav-item" @click="onClickWalletConnect">
          <img src="@/assets/images/icon_account.png" />
        </li>
        </ul>
      </div>
      </div>
    </nav>
    <el-drawer v-model="drawer" title="" :before-close="handleClose" :size="'403px'" :show-close="false">
      <div class="d-flex flex-column">
        <!-- <div class="other-div" @click="onChickAccount">
          <div class="other-img">
            <img src="@/assets/images/account/icon_assets.png" />
          </div>
          <div class="other-txt">
            <p>Account</p>
          </div>
        </div> -->
        <div class="other-div" @click="onChickLogout">
          <div class="other-img">
            <img src="@/assets/images/account/icon_logout.png" />
          </div>
          <div class="other-txt">
            <p>Logout</p>
          </div>
        </div>
      </div>
    </el-drawer>
    <WalletConnect :display="showDialog"></WalletConnect>
  </header>
  <div class="pop-loading" v-if="showLoading">
    <img src="@/assets/images/loading.png" width="144" height="144" />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  onMounted,
  onBeforeUnmount,
  inject,
  onBeforeMount,
  onUpdated,
} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import WalletConnect from "./WalletConnect.vue";

const eventHub = inject("$eventHub");

const store = useStore();
const router = useRouter();
const drawer = ref(false);

let showDialog = ref(false);
let showLoading = ref(false);

let headerList = reactive([
  { id: "1", name: "Home", title: "Home", clicked: true },
  { id: "4", name: "AirDrop", title: "AirDrop", clicked: false },
  {
    id: "2", name: "Event", title: "EVENTS", clicked: false, type: "dropdown", menuList: [
      { id: "2-1", name: "Referral", title: "Discord Invite Campaign" },
      { id: "2-2", name: "Challenge", title: "Screenshot Challenge" },
      { id: "2-3", name: "StickerVoting", title: "Sticker Voting" },
      { id: "2-4", name: "BountyHunter", title: "Bounty Hunter" },
      // { id: "2-5", name: "AirDrop", title: "AirDrop" },
    ]
  },
  { id: "3", name: "Ecosystem", title: "Ecosystem", clicked: false },
  { id: "5", name: "Space", title: "Products", clicked: false },
  { id: "6", name: "Docs", title: "Docs", clicked: false, type: "link", linkUrl: "https://ic1101.gitbook.io/ic1101-documentation-center" },
  { id: "7", name: "IC1101World", title: "IC1101 World", clicked: false },
]);

const handleClose = () => {
  drawer.value = false;
  eventHub.$emit("drawerClose");
};

const onClickWalletConnect = async () => {
  if (store.state.hasLogin == "true") {
    drawer.value = !drawer.value;
  } else {
    showDialog.value = !showDialog.value;
    // router.push("./register");
  }
};

const onClickLink = (item) => {
  if (item.linkUrl) {
    window.open(item.linkUrl, "_blank");
  }
};

const onChickAccount = () => {
  router.push("./account");
  drawer.value = false;
};


const onChickLogout = () => {
  drawer.value = false;
  eventHub.$emit("home:logout");
  store.commit("logout");
  router.push("./");
};

onBeforeMount(() => {
  eventHub.$on("update:display", (bool) => {
    showDialog.value = bool;
  });
  eventHub.$on("showLoading", (bool) => {
    showLoading.value = bool;
  });
  // 监听  $emit触发后在此处接收传过来的参数
  eventHub.$on("tabNav", (data) => {
    let _data = data;
    for (let i of headerList) {
      if (i.name == _data.name) {
        i.clicked = true;
      } else {
        i.clicked = false;
      }
    }
  });
});

onBeforeUnmount(() => { });
onUpdated(() => {
  // hasLogin.value = store.state.hasLogin == "true" ? true : false;
});
</script>

<style lang="less" scoped>
.navbar-collapse {
  flex-grow: 0;
}

.navbar-toggler {
  border: 1px solid white;
  background-color: white
}

.header {
  position: relative;
  width: 100vw;
}

.top_nav {
  // position: fixed;
  width: 100%;
  min-height: 74px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 100%);
}

.header_nav span {
  color: #fff;
}

.header_nav .clicked span,
.header_nav span:hover {
  color: #0cacff;
}

.header_nav /deep/ a {
  text-decoration: none;
}

.link {
  display: flex;
  align-items: center;
}

.account {
  margin: 0 10px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}

.header /deep/ .el-overlay {
  top: 74px;
}

.header /deep/ .el-drawer {
  background-color: #000;
  // height: 830px;
  width: 403px;
}



.pop-loading {
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(-360deg);
      -ms-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }

  img {
    animation: spin 3s linear infinite;
  }
}

.other-div {
  width: 320px;
  height: 202px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .other-txt {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p:nth-of-type(1) {
      font-size: 25px;
      color: #fff;
    }

    p:nth-of-type(2) {
      margin-top: 4px;
      font-size: 13px;
      color: #707070;
      text-align: center;
    }
  }
}
</style>
