<template>
  <div class="home">
    <div class="container">
      <div class="nothing" v-if="isLoading">
        <img src="@/assets/images/loading.png" alt="">
      </div>
      <div v-else>
        <div class="nothing" v-if="recommendList.length == 0">
          <img src="@/assets/images/Space/nothing.png" alt="">
        </div>
        <div class="images" v-else>
          <div v-for="(item, index) in recommendList" :key="index" class="image-middle">
            <el-card shadow="hover" :body-style="{ padding: '0px', backgroundColor: '#242424' }"
              @click="onClickItem(item)">
              <div style="position: relative;">
                <div class="image-box">
                  <img :src="item.icon" slot="reference" class="image" />
                </div>
                <div class="box-group">
                  <div class="box-title">{{ item.roomName }}</div>
                  <div class="box-info">
                    <div class="box-info-item">
                      <img src="@/assets/images/Space/like.png" width="16" height="18">
                      <div class="box-info-num">{{ item.thumbUpTimes }}</div>
                    </div>
                    <div class="box-info-item" style="margin-left: 23px;">
                      <img src="@/assets/images/Space/people.png" width="18" height="14">
                      <div class="box-info-num">{{ item.visitTimes }}</div>
                    </div>
                  </div>
                </div>

                <div v-if="item.status == 0" class="status-bg">
                  <div class="lv-yuan"></div>
                  <div class="status-info lv-label">Open</div>
                </div>
                <div v-else-if="item.status == 1" class="status-bg">
                  <div class="sequence-animation"></div>
                  <div class="status-info red-label">Activity</div>
                </div>

                <div v-if="item.pwd?.length > 0" class="lock-bg">
                  <img src="@/assets/images/Space/lock.png" alt="">
                </div>
              </div>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  onBeforeUnmount,
  reactive,
  ref,
  inject,
  onBeforeMount,
  onUpdated,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";


const router = useRouter();
const eventHub = inject("$eventHub");
const store = useStore();
const api = inject("$api");

let hasLogin = ref(false);
let userInfo = reactive({});

let recommendList = reactive([]);

let isLoading = ref(true);

const getRecommendList = async () => {
  isLoading.value = true;
  let params = {
    pageSize: -1,
    curIdx: 0,
    tags: "",
    wordKey: "",
    tab: 1
  }
  api.recommendList(params).then((res) => {
    if (res.errCode == 0) {
      recommendList.length = 0;
      for (let i = 0; i < res.data.length; i++) {
        recommendList.push(res.data[i])
      }
    }
    isLoading.value = false;
  });
}

const onClickItem = async (item) => {
  window.open(`https://space.ic1101.world/?spaceid=${item.id}`)
}

onBeforeMount(() => {
  getRecommendList();
});


</script>

<style lang="less" scoped>
.home {
  // width: 100vw;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(@/assets/images/home/img1_di.png) no-repeat center;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 74px;
}

.nothing {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.myButton {
  width: 306px;
  height: 58px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 306px 58px;
  line-height: 58px;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
}

.myButton:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 306px 58px;
}

.container {
  max-width: 1400px;
}

/* 图片总布局，样式 */
.images {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

/* 图片之间 */
.image-middle {
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 50px;
}

.image-box {
  width: 243px;
  height: 243px;
  overflow: hidden;
}

/* 单张图片样式 */
.image {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.image-box:hover .image {
  transform: scale(1.2);
}

.box-group {
  background-color: #d2d2d2;
  font-family: "Medium";
  padding: 2px 14px 6px;

  .box-title {
    font-size: 18px;
    color: #000;
  }

  .box-info {
    display: flex;
    margin-top: 4px;
    color: #9b9b9b;

    .box-info-item {
      display: flex;
      justify-content: center;
      align-items: center;

      .box-info-num {
        margin-left: 8px;
      }
    }
  }
}

.status-bg {
  position: absolute;
  width: 114px;
  height: 34px;
  background-color: #00000094;
  border-radius: 17px;
  top: 15px;
  left: 10px;
  display: flex;
  align-items: center;
  font-family: "Medium";

  .lv-yuan {
    margin-left: 10px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #13E82C;
  }

  .lv-label {
    color: #13E82C;
    font-size: 22px;
  }

  .red-label {
    color: #FF0A0A;
    font-size: 22px;
  }

  .status-info {
    margin-left: 10px;
  }
}

.lock-bg {
  position: absolute;
  width: 34px;
  height: 34px;
  background-color: #00000094;
  border-radius: 17px;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.sequence-animation {
  width: 30px;
  height: 30px;
  background-image: url('@/assets/images/Space/activity1.png');
  background-repeat: no-repeat;
  background-size: cover;
  animation: sequenceAnimation 2s steps(3) infinite;
}

@keyframes sequenceAnimation {
  0% {
    background-image: url('@/assets/images/Space/activity1.png');
  }

  33.3% {
    background-image: url('@/assets/images/Space/activity2.png');
  }

  66.6% {
    background-image: url('@/assets/images/Space/activity3.png');
  }

  100% {
    background-image: url('@/assets/images/Space/activity1.png');
  }
}
</style>
