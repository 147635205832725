<template>
  <div class="home">
    <div class="container" ref="clockNode">
      <div class="img-box ">
        <div>
          <video loop="true" autoplay="true" width="800" muted="true" src="../../assets/video/stickervoting.mp4" @click="onClickImg('end')" style="cursor: pointer;"></video>
        </div>
        <!-- <div class="row" v-if="!actEndBool">
          <div class="col">
            <img src="@/assets/images/Referral/active0626.jpg" alt="" width="800" @click="onClickImg('')"
              style="cursor: pointer;">
          </div>
        </div>
        <div class="row" v-else>
          <div class="col">
            <img src="@/assets/images/Referral/active0707.jpg" alt="" width="800" @click="onClickImg('')"
              style="cursor: pointer;">
          </div>
        </div> -->
      </div>
      <div class="clock-container">
        <flip-item :total="2" :current="timeArr[0]" style="z-index: 444;" />
        <flip-item :total="9" :current="timeArr[1]" style="z-index: 555;" />
        <div class="colon">D</div>
        <flip-item :total="2" :current="timeArr[2]" style="z-index: 444;" />
        <flip-item :total="9" :current="timeArr[3]" style="z-index: 555;" />
        <div class="colon">H</div>
        <flip-item :total="5" :current="timeArr[4]" style="z-index: 666;" />
        <flip-item :total="9" :current="timeArr[5]" style="z-index: 777;" />
        <div class="colon">M</div>
        <flip-item :total="5" :current="timeArr[6]" style="z-index: 888;" />
        <flip-item :total="9" :current="timeArr[7]" style="z-index: 999;" />
        <div class="colon">S</div>
      </div>
      <div class="title-box">
        <div class="title">{{ titleLabel }}</div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {
  onBeforeUnmount,
  reactive,
  ref,
  inject,
  onBeforeMount,
  onUpdated,
  onMounted
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import FlipItem from "./FlipItem.vue";

const router = useRouter();
const eventHub = inject("$eventHub");
const store = useStore();
const utils = inject("$utils");

const timeArr = ref([0, 0, 0, 0, 0, 0]);
let timer = null;
let startTime = 1719388800000;
let endTime = 1720306800000;
let secondsTime = 0;
let titleLabel = ref("Start Time: 8:00 AM (UTC), June 26th 2024");

let actEndBool = ref(false);

let clockNode = ref(null);

onBeforeMount(() => {
  startTimer();
});

onMounted(() => {
  updateScale();
});

onBeforeUnmount(() => {
  stopTimer();
});

const updateScale = () => {
  let scale = window.innerWidth / 1920;
  clockNode.value.style.transform = `scale(${scale})`;
}

const startTimer = () => {
  console.log("启动定时器+++++++++++++++++++++++++++++");
  let now = new Date().getTime();
  let leftTime = startTime - now;
  let actEndTime = endTime - now;
  if (actEndTime <= 0) {
    actEndBool.value = true;
  }
  if (leftTime <= 0) {
    leftTime = endTime - now;
    titleLabel.value = "End Time: 11:00 PM (UTC), July 6th 2024";
  }
  secondsTime = Math.floor(leftTime / 1000);
  timer = setInterval(() => {
    secondsTime--;
    if (secondsTime < 0) {
      secondsTime = 0;
    }
    timeArr.value = utils.getSecondsTimeArr(secondsTime);
  }, 1000);
};

const stopTimer = () => {
  console.log("停止定时器----------------------------");
  clearInterval(timer);
};

const onClickImg = (str) => {
  window.open("https://medium.com/@IC1101World/ic1101-sticker-voting-choose-our-mascot-765e61334473");
  // switch (str) {
  //   case "1":
  //     window.open("https://medium.com/@lC1101World/7bbd74a6d879");
  //     break;
  //   case "6":
  //     window.open("https://medium.com/@IC1101World/ic1101-discord-invite-campaign-ii-ic1101-star-citizen-317e0eae6d91");
  //     break;
  //   case "end":
  //     window.open("https://discord.gg/kbqZFQTdNA");
  //     break;
  //   default:
  //     break;
  // }
};

</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(@/assets/images/Ecosystem/bg.png);

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;

  padding-top: 74px;

  .container {
    max-width: 1400px;
  }

  .title-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
  }

  .title {
    font-size: 36px;
  }

  .img-box {
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clock-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  }

  .colon {
    font-size: 24px;
    height: 150px;
    font-weight: bold;
    padding: 20px 20px;
    // display: flex;
    // justify-content: space-around;
    // flex-direction: column;
  }

  // .colon::after,
  // .colon::before {
  //   content: "";
  //   display: block;
  //   width: 30px;
  //   height: 30px;
  //   background: rgba(255, 255, 255, 1);
  //   border-radius: 50%;
  // }


}

.myButton {
  width: 306px;
  height: 58px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 306px 58px;
  line-height: 58px;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
}

.myButton:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 306px 58px;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
