<template>
	<div class="dialog" v-if="visible">
		<el-dialog v-model="visible" :title="title" width="380px" :before-close="handleClose" :custom-class="customClass"
			:close-on-click-modal="false" :close-on-press-escape="false">
			<template #default>
				<slot name="content">
					<!-- 自定义对话框内容 -->
				</slot>
			</template>
			<template #footer>
				<el-button v-if="showCancelButton" @click="handleCancel">{{ cancelButtonText }}</el-button>
				<el-button @click="handleConfirm">{{ confirmButtonText }}</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script setup>

import { toRefs, defineProps, inject, defineEmits } from 'vue';

const props = defineProps({
	title: {
		type: String,
		default: 'Tips'
	},
	visible: {
		type: Boolean,
		default: false
	},
	customClass: {
		type: String,
		default: ''
	},
	confirmButtonText: {
		type: String,
		default: '确认'
	},
	cancelButtonText: {
		type: String,
		default: '取消'
	},
	showCancelButton: {
		type: Boolean,
		default: true
	}
});

let { visible } = toRefs(props);

const emit = defineEmits(['update:visible', 'confirm', 'cancel', 'close']);


const handleConfirm = () => {
	emit('confirm');
};

const handleCancel = () => {
	emit('cancel');
};

const handleClose = (done) => {
	emit('close');
};
</script>

<style lang="less" scoped>
.dialog /deep/ .el-dialog {
	background: rgba(0, 0, 0, 0.76);
}

.dialog /deep/ .el-dialog__body {
	color: #fff;
}

.dialog /deep/ .el-dialog__title {
	color: #fff;
}

.dialog /deep/ .el-button {
	width: 100px;
	height: 40px;
	background: url(@/assets/images/btn_min_1.png) no-repeat center;
	background-size: 100px 40px;
	line-height: 40px;
	text-align: center;
	cursor: pointer;
	border: none;
	color: #fff;
}

.dialog /deep/ .el-button:hover {
	background: url(@/assets/images/btn_min_2.png) no-repeat center;
}
</style>
