import Web3 from "web3";

export class metaMask {
  async connect(cb) {
    if (
      window["ethereum"] !== "undefined" &&
      window["ethereum"] !== undefined
    ) {
      let isMetaMask = window["ethereum"].isMetaMask;
      if (isMetaMask) {
        let accounts = await window["ethereum"].request({
          method: "eth_requestAccounts",
        });
        // this.web3 = new Web3(window.web3.currentProvider);
        this.accountAddress = accounts[0];
        // await this.initToken();
        this.sign(this.accountAddress, () => {
          cb(this.accountAddress);
        });
      } else {
        window.location.href =
          "https://metamask.app.link/dapp/space.ic1101.world/";
        return;
      }
    } else {
      window.location.href =
        "https://metamask.app.link/dapp/space.ic1101.world/";
      return;
    }
  }

  async sign(address, cb) {
    try {
      const signStr = `Welcome to IC1101 Exhibition Space!`;
      await window["ethereum"].request({
        method: "personal_sign",
        params: [address, signStr],
      });
      cb(address);
    } catch (error) {
      console.log(error);
    }
  }

  //判断链id
  async getChainId() {
    //！链id不是马上拿到的，如果通过链id来判断是不是主网的方式，请注意异步
    try {
      let chainId = await this.web3.eth.getChainId();
      return chainId;
    } catch (error) {
      console.error("error:", error);
    }
  }

  // 切换钱包chainId
  async changeChain() {
    try {
      let chainId = await this.getChainId();
      if (chainId != this.curChainId) {
        window["ethereum"]
          .request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId: Web3.utils.numberToHex(this.curChainId), // 目标链ID
              },
            ],
          })
          .then(() => {
            console.log("成功");
          })
          .catch((error) => {
            console.error(`ERROR:${error.message}`);
            if (error.code === 4902) {
              window["ethereum"].request({
                method: "wallet_addEthereumChain",
                params: [providerConfig[this.curChainId]],
              });
            } else {
              uiManager.Show(ToastAuto.URL, "fail");
            }
          });
        //切换网络
      }
    } catch (error) {
      console.log("error: 切链失败", error);
      uiManager.Show(ToastAuto.URL, "fail");
    }
  }

  async killWalletConnect() {}

  async transferUSDT(targetAddress, amount) {
    amount = this.web3.utils.toWei(amount + "", "ether");
    let transferData = await this.contractUSDT.methods
      .transfer(targetAddress, amount + "")
      .encodeABI();
    let tx = await this.ethReceipt("USDT", transferData);
    let tx_receipt = await this.web3.eth.sendTransaction(tx);
    console.log("tx_receipt:", tx_receipt);
  }

  async mint(amount) {
    let cost = await this.getCost();
    let data = await this.contractJumboNFT.methods.mint(amount).encodeABI();
    cost = this.web3.utils.toHex(cost);
    let tx = await this.ethReceipt("JumboNFT", data, cost);
    let tx_receipt = await this.web3.eth.sendTransaction(tx);
    console.log("tx_receipt:", tx_receipt);
  }

  async allowlistMint() {
    let cost = await this.getCost();
    let data = await this.contractJumboNFT.methods.allowlistMint().encodeABI();
    cost = this.web3.utils.toHex(cost);
    let tx = await this.ethReceipt("JumboNFT", data, cost);
    let tx_receipt = await this.web3.eth.sendTransaction(tx);
    console.log("tx_receipt:", tx_receipt);
  }

  async partnerMint() {
    let cost = await this.getCost();
    let data = await this.contractJumboNFT.methods.partnerMint().encodeABI();
    cost = this.web3.utils.toHex(cost);
    let tx = await this.ethReceipt("JumboNFT", data, cost);
    let tx_receipt = await this.web3.eth.sendTransaction(tx);
    console.log("tx_receipt:", tx_receipt);
  }

  async publicMint() {
    let cost = await this.getCost();
    let data = await this.contractJumboNFT.methods.publicMint().encodeABI();
    cost = this.web3.utils.toHex(cost);
    let tx = await this.ethReceipt("JumboNFT", data, cost);
    let tx_receipt = await this.web3.eth.sendTransaction(tx);
    console.log("tx_receipt:", tx_receipt);
  }
}
