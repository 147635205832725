export class OkxSolanaApi {
  okxwallet = window["okxwallet"];

  static _instance = null;
  static get instance() {
    if (this._instance == null) {
      this._instance = new OkxSolanaApi();
    }
    return this._instance;
  }

  async init(cb) {
    this.okxwallet.solana.on("disconnect", this.disconnectHandler.bind(this));
    this.okxwallet.solana.on(
      "accountChanged",
      this.accountChangedHandler.bind(this)
    );
    if (cb) {
      cb();
    }
  }

  async removeHandler() {
    this.okxwallet.solana.off("disconnect", this.disconnectHandler);
    this.okxwallet.solana.off("accountChanged", this.accountChangedHandler);
  }

  async connect(cb) {
    try {
      const result = await this.okxwallet.solana.connect();
      let address = result.publicKey.toString();
      cb(address);
    } catch (error) {
      this.errorHandler(error);
    }
  }

  async sign(address, cb) {
    try {
      const signStr = `Welcome to IC1101 Exhibition Space!`;
      let encodedMessage = new TextEncoder().encode(signStr);
      await this.okxwallet.solana.signMessage(encodedMessage, "utf8");
      cb();
    } catch (error) {
      this.errorHandler(error);
    }
  }

  // 断开
  disconnectHandler() {
    console.log("disconnected!");
    this.logout();
  }

  // 账户变更
  accountChangedHandler() {
    console.log("accountChangedHandler!");
    this.logout();
  }
  logout() {}
}
