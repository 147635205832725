<template>
  <div class="home">
    <div class="container" ref="clockNode">
      <div class="row">
        <div class="col-md-5 col-lg-5">
          <div class="my-card1">
            <div>{{ goldIcWeb }}</div>
          </div>
          <div class="my-card2">
            <div>{{ inviteRefCnt }}</div>
          </div>
        </div>
        <main class="col-md-7 col-lg-7">
          <div class="main-box">
            <div class="main-title">Social to Earn</div>
            <div class="main-desc">Complete social tasks for $IEC airdrop. Post-TGE, $IEC will convert to $IWT
              gradually, based on a
              ratio.</div>
            <div class="main-line"></div>
            <div class="task-box" style="margin-top: 24px;">
              <h2>One-time Tasks</h2>
              <div class="task flex" v-for="task in oneTimeTasks" :key="task.id" style="height: 80px;">
                <div class="task-info">
                  <div class="custom-control custom-checkbox" style="height: 40px;">
                    <input type="checkbox" class="custom-control-input" :id="`customCheck${task.id}`" disabled
                      :checked="task.status != 1">
                    <label class="custom-control-label" :for="`customCheck${task.id}`">{{ task.name }}</label>
                  </div>
                  <div class="task-button" @click="onClickTaskButton(task)" v-if="task.status != 3">{{ "+" + task.gold +
              " $IEC" }}</div>
                </div>
                <div class="main-line"></div>
              </div>
            </div>
            <div class="task-box">
              <h2>Daily Task</h2>
              <div class="task flex" v-for="task in dailyTasks" :key="task.id" style="height: 80px;">
                <div class="task-info">
                  <div class="custom-control custom-checkbox" style="height: 40px;">
                    <input type="checkbox" class="custom-control-input" :id="`customCheck${task.id}`" disabled
                      :checked="task.status != 1">
                    <label class="custom-control-label" :for="`customCheck${task.id}`">{{ task.name }}</label>
                  </div>
                  <div class="task-button" @click="onClickTaskButton(task)" v-if="task.status != 3">{{ "+" + task.gold +
              " $IEC" }}</div>
                </div>
                <div class="main-line"></div>
              </div>
            </div>
            <div class="task-box">
              <h2>Referral Task</h2>
              <div class="task flex" v-for="task in referralTasks" :key="task.id" style="height: 80px;">
                <div class="task-info">
                  <div class="referral-box display-flex flex-row justify-content" style="width: 100%;"
                    v-if="userInfo.inviteCode == null || userInfo.inviteCode == ''">
                    <!-- <div style="width: 60px; height: 40px;"></div> -->
                    <div class="task-button" @click="onClickTaskButton(task)">get invite code</div>
                  </div>
                  <div class="referral-box" v-else @click="onClickCopyInviteUrl()">
                    <div style="width: 60px; height: 40px;"></div>
                    <label class="custom-control-label">{{ `https://space.ic1101.world/?invite=${userInfo.inviteCode}`
                      }}</label>
                    <img src="@/assets/images/airDrop/copy.png" alt="" style="margin-left: 10px">
                  </div>
                </div>
                <div class="main-line"></div>
              </div>
            </div>

            <div class="claimBtn" @click="onClickClaimBtn">
              <div style="font-size: 38px;">CLAIM</div>
              <div>YOUR $IEC TO YOUR IC1101 WALLET</div>
            </div>

            <div class="rules-info">⭐1 valid invite = 200 $IEC ⭐ Minimum withdrawal: 500 $IEC.
              <img src="@/assets/images/airDrop/info.png" alt="" @click="isShowRules = !isShowRules">
            </div>
          </div>
        </main>
      </div>
      <WalletConnect :display="showDialog"></WalletConnect>
      <CustomDialog :visible="dialogVisible" :title="dialogTitle" :confirmButtonText="confirmButtonText"
        :cancelButtonText="cancelButtonText" :showCancelButton="showCancelButton" @confirm="handleConfirm"
        @cancel="handleCancel" @close="handleClose">
        <template #content>
          {{ dialogContent }}
        </template>
      </CustomDialog>
    </div>
    <div class="rules-box" v-if="isShowRules" @click.stop="isShowRules = !isShowRules">
      <img src="@/assets/images/airDrop/airDrop3.png" alt="">
    </div>
    <div class="pop-loading" v-if="showLoading">
      <img src="@/assets/images/loading.png" width="144" height="144" />
    </div>
  </div>
</template>


<script setup>
import {
  onBeforeUnmount,
  reactive,
  ref,
  inject,
  onBeforeMount,
  onUpdated,
  onMounted
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import WalletConnect from "@/components/PC/WalletConnect.vue";
import CustomDialog from "@/components/PC/CustomDialog.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import discordInfo from "@/utils/discordInfo";
import axios from "axios";
import QS from "qs";

const router = useRouter();
const eventHub = inject("$eventHub");
const store = useStore();
const utils = inject("$utils");
const api = inject("$api");

let isClickedBtn = ref(false);

let showDialog = ref(false);

let clockNode = ref(null);

let userInfo = reactive({});
let hasLogin = ref(false);

let goldIcWeb = ref(0);
let inviteRefCnt = ref(0);

let isShowRules = ref(false);

// let taskList = reactive([]);

let oneTimeTasks = reactive([]);
let dailyTasks = reactive([]);
let referralTasks = reactive([]);

let showLoading = ref(false);

const dialogVisible = ref(false);
const dialogTitle = ref('Tips');
const confirmButtonText = ref('确定');
const cancelButtonText = ref('取消');
const showCancelButton = ref(false);
const dialogContent = ref(``)
const dialogConfirm = ref(() => { });

onMounted(() => {
  updateScale();
});

onBeforeUnmount(() => {
  eventHub.$off("home:login", homeLogin);
  eventHub.$off("update:display", updateDisplay);
});

onBeforeMount(() => {
  hasLogin.value = store.state.hasLogin == "true" ? true : false;
  if (hasLogin.value == true) {
    store.commit("getUserInfo");
    userInfo = store.state.userInfo;
  }
  //登录成功
  eventHub.$on("home:login", homeLogin);
  eventHub.$on("update:display", updateDisplay);
  getTaskPannel();
});


const handleConfirm = () => {
  console.log('确认按钮被点击');
  dialogVisible.value = false;
  dialogConfirm.value();
};

const handleCancel = () => {
  console.log('取消按钮被点击');
  dialogVisible.value = false;
};

const handleClose = () => {
  console.log('对话框关闭');
  dialogVisible.value = false;
};

const homeLogin = () => {
  showDialog.value = false;
  hasLogin.value = true;
  store.commit("getUserInfo");
  userInfo = store.state.userInfo;
  getTaskPannel();
}

const updateDisplay = (bool) => {
  showDialog.value = bool;
}

const updateScale = () => {
  let scale = window.innerWidth / 1920;
  clockNode.value.style.transform = `scale(${scale})`;
}

const getTaskPannel = () => {
  let token = userInfo.token;
  let params = {
    tabId: 20,
    token,
  };
  showLoading.value = true;
  api.zhTaskPannel(params).then((res) => {
    showLoading.value = false;
    if (res.errCode == 0) {
      goldIcWeb.value = res.data.goldIcWeb;
      inviteRefCnt.value = res.data.inviteRefCnt;
      oneTimeTasks.length = 0;
      dailyTasks.length = 0;
      referralTasks.length = 0;
      for (let i = 0; i < res.data.taskList.length; i++) {
        let task = res.data.taskList[i];
        switch (task.eventId) {
          case 31:
            task.buttonText = "Follow";
            break;
          case 36:
            task.buttonText = "Join";
            break;
          case 37:
            task.buttonText = "Line";
            break;
          case 38:
            task.buttonText = "Invite";
            break;
          default:
            break;
        }
        if (task.taskType == 5) {
          oneTimeTasks.push(task);
        } else if (task.taskType == 6) {
          dailyTasks.push(task);
        } else if (task.taskType == 7) {
          referralTasks.push(task);
        }
      }
    } else {
      eventHub.$emit("home:logout");
      store.commit("logout");
      router.push("./");
    }
  })
}


const onClickTaskButton = (task) => {
  if (isClickedBtn.value == true) {
    return;
  }
  if (hasLogin.value == false) {
    showDialog.value = true;
    return;
  }

  if (task.status == 1) {
    completeTask(task);
  } else if (task.status == 2) {
    rewardHandler(task.taskId);
  }
}

const completeTask = (task) => {
  switch (task.eventId) {
    case 31:
      window.open('https://x.com/IC1101World', '_blank')
      taskEvent(task);
      break;
    case 36:
      let joinDiscord = localStorage.getItem("joinDiscord") == "true" ? true : false;
      if (joinDiscord) {
        verifyDiscordCode();
      } else {
        localStorage.setItem("joinDiscord", 'true');
        localStorage.setItem("taskId", task.taskId);
        window.location.href = discordInfo.href
      }
      break;
    case 37:
      window.open('https://x.com/IC1101World', '_blank')
      taskEvent(task);
      break;
    case 38:
      if (userInfo.inviteCode == null || userInfo.inviteCode == '') {
        getInviteCode(task);
      } else {
        let url = 'https://space.ic1101.world/?invite=' + userInfo.inviteCode;
        copyHandler(url);
      }
      break;
    default:
      break;
  }
}


// axios discord数据
const axiosPos = async (url, params) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

const axiosGet = async (url, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        headers,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
};

const verifyDiscordCode = async (task) => {
  let code = localStorage.getItem("discordCode");
  if (code) {
    let params = {
      client_id: discordInfo.client_id,
      client_secret: discordInfo.client_secret,
      grant_type: "authorization_code",
      code,
      redirect_uri: discordInfo.redirect_url,
      scope: "identify%20guilds",
    };
    try {
      let tokenData = await axiosPos(
        "https://discord.com/api/oauth2/token",
        params
      );
      if (tokenData.access_token) {
        let headers = {
          authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        }

        let myGuilds = await axiosGet(
          "https://discord.com/api/users/@me/guilds",
          headers
        );
        let ic1101GuildsId = "973881922910564383";
        let hasIC1101Guild = false;
        for (let i = 0; i < myGuilds.length; i++) {
          let item = myGuilds[i];
          if (item.id == ic1101GuildsId) {
            hasIC1101Guild = true;
          }
        }
        if (hasIC1101Guild == true) {
          taskEvent(task);
        }
      } else {
        localStorage.removeItem("discordCode");
      }

    } catch (error) {
      console.log("error", error);
      localStorage.removeItem("discordCode");
    }
  } else {
    window.location.href = discordInfo.href
  }
};

const getInviteCode = () => {
  let token = userInfo.token;
  let params = {
    token
  };
  showLoading.value = true;
  api.zhGenInviteCode(params).then((res) => {
    showLoading.value = false;
    isClickedBtn.value = false;
    if (res.errCode == 0) {
      userInfo.inviteCode = res.data.inviteCode;
      store.commit("setUserInfo", userInfo);
      onClickCopyInviteUrl();
    }
  });
}

const onClickCopyInviteUrl = () => {
  let url = 'https://space.ic1101.world/?invite=' + userInfo.inviteCode;
  let str = `Hey IC1101 citizen! Share the excitement of our AI-driven social platform with your buddies! The more we grow, the bigger the rewards!

${url}`;
  copyHandler(str);
}


const copyHandler = (string) => {
  var input = document.createElement("textArea"); // 直接构建input
  input.value = string; // 设置内容
  console.log(input.value);

  document.body.appendChild(input); // 添加临时实例
  input.select(); // 选择实例内容
  document.execCommand("Copy"); // 执行复制
  document.body.removeChild(input); // 删除临时实例

  ElMessage({
    message: "Invite Code Copied",
    type: "success",
  });
};

const rewardHandler = (taskId) => {
  isClickedBtn.value = true;
  let token = userInfo.token;
  let params = {
    token,
    taskId: taskId,
    tabId: 20,
  };
  showLoading.value = true;
  api.zhTaskReward(params).then((res) => {
    showLoading.value = false;
    isClickedBtn.value = false;
    if (res.errCode == 0) {
      console.log(" ===领取成功=== ", res.data.pannelDto);
      goldIcWeb.value = res.data.pannelDto.goldIcWeb;
      inviteRefCnt.value = res.data.pannelDto.inviteRefCnt;
      oneTimeTasks.length = 0;
      dailyTasks.length = 0;
      referralTasks.length = 0;
      for (let i = 0; i < res.data.pannelDto.taskList.length; i++) {
        let task = res.data.pannelDto.taskList[i];
        switch (task.eventId) {
          case 31:
            task.buttonText = "Follow";
            break;
          case 36:
            task.buttonText = "Join";
            break;
          case 37:
            task.buttonText = "Line";
            break;
          case 38:
            task.buttonText = "Invite";
            break;
          default:
            break;
        }
        if (task.taskType == 5) {
          oneTimeTasks.push(task);
        } else if (task.taskType == 6) {
          dailyTasks.push(task);
        } else if (task.taskType == 7) {
          referralTasks.push(task);
        }
      }
    }
  });
}

const taskEvent = (task) => {
  isClickedBtn.value = true;
  let token = userInfo.token;
  let params = {
    token,
    taskId: task.taskId,
    tabId: 20,
  };
  showLoading.value = true;
  api.zhTaskEvent(params).then((res) => {
    showLoading.value = false;
    isClickedBtn.value = false;
    if (res.errCode == 0) {
      task.status = 2;
    }
  });
}

const onClickClaimBtn = () => {
  if (isClickedBtn.value == true) {
    return;
  }
  if (hasLogin.value == false) {
    showDialog.value = true;
    return;
  }

  // amount 有门槛 amount 多少？？？？
  if (goldIcWeb.value < 500) {
    dialogContent.value = `Sorry, Citizen! your $IEC balance is currently too low for a withdrawal. Invite some friends to join us and let's get that balance growing now!`;
    dialogVisible.value = true;
    confirmButtonText.value = 'Sure';
    dialogConfirm.value = () => { }
    return;
  }
  let token = userInfo.token;
  let params = {
    token,
    amount: goldIcWeb.value,
  }
  showLoading.value = true;
  api.icWebSiteClaim(params).then((res) => {
    showLoading.value = false;
    if (res.errCode == 0) {
      goldIcWeb.value = res.data.goldIcWeb;
      dialogContent.value = `Congratulations, Citizen! Your $IEC airdrop reward is now available in your IC1101 wallet. Log in immediately to view your balance!`;
      dialogVisible.value = true;
      confirmButtonText.value = 'Sure';
      dialogConfirm.value = () => {
        window.open('https://space.ic1101.world', '_blank')
      }
    }
  });
}
// 
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(@/assets/images/Ecosystem/bg.png);

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;

  padding-top: 74px;

  .container {
    // background: #fff;
    // width: 100%;
    // height: 100%;
    max-width: 1400px;

    .my-card1 {
      width: 564px;
      height: 278px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url(@/assets/images/airDrop/airDrop1.png);

      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      background-attachment: fixed;

      font-size: 128px;
      color: #fff;
      text-shadow: -5px 5px 10px #30ffe4;
      padding-top: 60px;

      margin-top: 120px;
    }

    .my-card2 {
      width: 564px;
      height: 278px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url(@/assets/images/airDrop/airDrop2.png);

      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      background-attachment: fixed;


      font-size: 128px;
      color: #fff;
      text-shadow: -5px 5px 10px #30ffe4;
      padding-top: 60px;
      margin-top: 24px;
    }

    .main-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .main-title {
        font-size: 38px;
        padding-bottom: 24px;
      }

      .main-desc {
        font-size: 16px;
        padding-bottom: 24px;
      }


      .main-line {
        width: 100%;
        height: 2px;
        background-color: rgba(120, 120, 120, 0.5);
      }

      .task-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;

        h2 {
          margin-bottom: 24px;
        }

        .task {
          width: 100%;
        }

        .task-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .task-button {
          margin-top: -8px;
          width: 210px;
          height: 40px;
          background: url(@/assets/images/btn_1.png) no-repeat center;
          background-size: 210px 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          font-size: 24px;
        }

        .task-button:hover {
          background: url(@/assets/images/btn_2.png) no-repeat center;
        }

        .referral-box {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
        }
      }

      .rules-info {
        font-size: 20px;
        color: #19b4cb;
        margin-top: 24px;
      }
    }

  }

}

.rules-box {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;

}

/* 隐藏默认复选框 */
.custom-checkbox .custom-control-input {
  position: absolute;
  opacity: 0;
}

/* 自定义复选框样式 */
.custom-checkbox .custom-control-label {
  position: relative;
  padding-left: 60px;
  /* 留出足够的空间用于自定义图片 */
  cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  /* 图片宽度 */
  height: 40px;
  /* 图片高度 */
  background: url(@/assets/images/airDrop/airDropChoose1.png) no-repeat center center;
  /* 默认未选中图片 */
  background-size: cover;
  /* 使图片覆盖整个区域 */
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background: url(@/assets/images/airDrop/airDropChoose2.png) no-repeat center center;
  /* 选中状态图片 */
}

.myButton {
  width: 306px;
  height: 58px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 306px 58px;
  line-height: 58px;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
}

.myButton:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 306px 58px;
}

.claimBtn {
  width: 778px;
  height: 110px;
  background: url(@/assets/images/airDrop/claimBtn.png) no-repeat center;
  background-size: 778px 110px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.claimBtn:hover {
  background: url(@/assets/images/airDrop/claimBtn.png) no-repeat center;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}


.pop-loading {
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(-360deg);
      -ms-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }

  img {
    animation: spin 3s linear infinite;
  }
}
</style>
