// const discordInfo = {
//   client_id: "1078594419940405248",
//   client_secret: "AxY35LY-JBqnxAvZ-YsJwrFyJIk8cvbZ",
//   redirect_url: "https://devres.ic1101.world",
//   href: "https://discord.com/oauth2/authorize?client_id=1078594419940405248&response_type=code&redirect_uri=https%3A%2F%2Fdevres.ic1101.world&scope=guilds+identify",
// };

const discordInfo = {
  client_id: "1078594419940405248",
  client_secret: "AxY35LY-JBqnxAvZ-YsJwrFyJIk8cvbZ",
  redirect_url: "https://ic1101.world",
  href: "https://discord.com/oauth2/authorize?client_id=1078594419940405248&response_type=code&redirect_uri=https%3A%2F%2Fic1101.world&scope=guilds+identify",
};

// const discordInfo = {
//   client_id: "1078594419940405248",
//   client_secret: "AxY35LY-JBqnxAvZ-YsJwrFyJIk8cvbZ",
//   redirect_url: "http://localhost:8080",
//   href: "https://discord.com/oauth2/authorize?client_id=1078594419940405248&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A8080&scope=guilds+identify",
// };

// const discordInfo = {
//   client_id: "1078594419940405248",
//   client_secret: "AxY35LY-JBqnxAvZ-YsJwrFyJIk8cvbZ",
//   redirect_url: "https://telgather.com",
//   href: "https://discord.com/oauth2/authorize?client_id=1078594419940405248&response_type=code&redirect_uri=https%3A%2F%2Ftelgather.com&scope=guilds+identify",
// };

export default discordInfo;
