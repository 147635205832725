<template>
  <div class="home">
    <div class="container">
      <div class="box-top">
        <img src="@/assets/images/Ecosystem/IC1101Ecosystem.png" alt="">
      </div>
      <div class="row box-item">
        <div class="col d-flex align-items-center  justify-content-center" v-for="(item, index) in ecosystemList">
          <div v-if="item.type === 'List'" class="box-item-list">
            <div class="col box-item-title">
              <img :src="item.title" alt="">
            </div>
            <div class="col box-item-list-item" style="margin-top: 10px;">
              <div class="box-item-list-item-div" @click="() => onClickBox(item.list[0])">
                <img :src="item.list[0].icon" alt="">
                <div class="box-item-info">{{ item.list[0].info }}</div>
              </div>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col box-item-list-item">
                <div class="box-item-list-item-div" @click="() => onClickBox(item.list[1])">
                  <img :src="item.list[1].icon" alt="">
                  <div class="box-item-info">{{ item.list[1].info }}</div>
                </div>
              </div>
              <div class="col box-item-list-item">
                <div class="box-item-list-item-div" @click="() => onClickBox(item.list[2])">
                  <img :src="item.list[2].icon" alt="">
                  <div class="box-item-info">{{ item.list[2].info }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="item.type === 'More'">
            <div class="col box-item-more">
              <img :src="item.icon" alt="">
              <div class="box-item-info">{{ item.info }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="pattern-bottom"></div> -->
  </div>
  <div v-if="showDialog">
    <div class="dialog">
      <VideoPlayer v-if="showVideo" :videoSource="videoSource"
        :onClose="() => { showVideo = false; showDialog = false }" />
      <ImagePlayer v-if="showImage" :imageSource="imageSource"
        :onClose="() => { showImage = false; showDialog = false }" />
    </div>
  </div>

  <div class="pattern">
    <!-- <div class="pattern-left"></div>
    <div class="pattern-right"></div> -->
    <!-- <div class="pattern-bottom"></div> -->
  </div>

</template>

<script setup>
import {
  onBeforeUnmount,
  reactive,
  ref,
  inject,
  onBeforeMount,
  onUpdated,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import VideoPlayer from '@/components/PC/VideoPlayer.vue';
import ImagePlayer from '@/components/PC/ImagePlayer.vue';



const router = useRouter();
const eventHub = inject("$eventHub");
const store = useStore();

let hasLogin = ref(false);
let userInfo = reactive({});
let showVideo = ref(false);
let videoSource = ref(null);
let showImage = ref(false);
let imageSource = ref(null);
let showDialog = ref(false);

const ecosystemList = reactive([
  {
    type: 'List',
    title: require("@/assets/images/Ecosystem/12.png"),
    list: [
      {
        icon: require("@/assets/images/Ecosystem/1.png"),
        info: "Explore interact-to-earn and decorate-to-earn features together!",
        type: 'url',
        link: "https://space.ic1101.world/",
      },
      {
        icon: require("@/assets/images/Ecosystem/2.png"),
        info: "Discover our new 'Dress-to-Earn' and 'Purchase-to-Earn' features, designed to enhance your avatar experience! ",
        type: 'url',
        link: "https://space.ic1101.world/",
      },
      {
        icon: require("@/assets/images/Ecosystem/3.png"),
        info: "Ready to create your own meeting space and host gatherings with thousands, lag-free? ",
        type: 'url',
        link: "https://space.ic1101.world/",
      },
    ]
  },
  {
    type: 'List',
    title: require("@/assets/images/Ecosystem/13.png"),
    list: [
      {
        icon: require("@/assets/images/Ecosystem/4.png"),
        info: "Let's delve into a realm of intelligent chatbots, personalized content, and AI-driven recommendations！",
        type: 'url',
        link: "https://space.ic1101.world/",
      },
      {
        icon: require("@/assets/images/Ecosystem/5.png"),
        info: "Would you like to experience high-quality AAA and play-to-earn games in Web3? Now, it's the time!",
        type: 'url',
        link: "https://space.ic1101.world/",
      },
      {
        icon: require("@/assets/images/Ecosystem/6.png"),
        info: "A huge marketplace for NFT collections-Realizable 3D version of Opensea.",
        type: 'url',
        link: "https://space.ic1101.world/",
      },
    ]
  },
  {
    type: 'More',
    icon: require("@/assets/images/Ecosystem/doubt.png"),
    info: "Stay tuned for what's coming next!",
  }
])

const onClickBox = (item) => {
  // switch (item.type) { // 跳转到对应页面
  //   case 'url':
  //     window.open(item.link);
  //     break;
  //   case 'image':
  //     showDialog.value = true;
  //     showImage.value = true;
  //     imageSource.value = item.link;
  //     break;
  //   case 'video':
  //     showDialog.value = true;
  //     showVideo.value = true;
  //     videoSource.value = item.link;
  //     break;
  //   default:
  //     break;
  // }
}

onBeforeMount(() => {

});


onBeforeUnmount(() => {

});

onUpdated(() => {

});

</script>

<style lang="less" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(@/assets/images/Ecosystem/bg.png);

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-attachment: fixed;

  padding-top: 74px;

  .container {
    max-width: 1400px;
  }

  .box-top {

    font-size: 18px;
    font-family: 'Regular';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .box-item {
    margin-top: 30px;
  }

  .box-item-list {
    width: 546px;
    height: 680px;
    background: url(@/assets/images/Ecosystem/boxBg.png) no-repeat center;
    background-size: 546px 680px;

    .box-item-title {
      width: 546px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .box-item-list-item {
      display: flex;
      justify-content: center;
    }

    .box-item-list-item-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      height: 260px;
    }

    .box-item-list-item-div img {
      width: 200px;
    }
  }

  .box-item-info {
    margin-top: 5px;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-family: 'Regular';
  }

  .box-item-more {
    height: 680px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .pattern-bottom {
    margin-top: 10px;
    width: 1778px;
    height: 54px;
    background: url(@/assets/images/Ecosystem/pattern2.png) no-repeat center;
    background-size: 1778px 54px;
  }
}

.myButton {
  width: 306px;
  height: 58px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 306px 58px;
  line-height: 58px;
  text-align: center;
  cursor: pointer;
  font-size: 24px;
}

.myButton:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 306px 58px;
}

.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pattern {
  .pattern-left {
    top: calc(50% - 380px);
    left: 50px;
    position: fixed;
    width: 12px;
    height: 760px;
    background: url(@/assets/images/Ecosystem/pattern1.png) no-repeat center;
    background-size: 12px 760px;
    transform: scaleX(-1);
  }

  .pattern-right {
    top: calc(50% - 380px);
    right: 50px;
    position: fixed;
    width: 12px;
    height: 760px;
    background: url(@/assets/images/Ecosystem/pattern1.png) no-repeat center;
    background-size: 12px 760px;
  }
}
</style>
