<template>
  <div class="home">
    <div class="div">
      <div class="leftdiv">
        <span class="info"><span>Multi-chain</span> open metaverse platform</span>

        <div class="box2">
          <div class="infodiv">
            <div>Minimum System Requirements</div>
            <div style="margin-top: 8px">
              <div>OS：</div>
              <div>Windows 10 64-bit</div>
            </div>
            <div>
              <div>RAM：</div>
              <div>16 GB</div>
            </div>
            <div>
              <div>CPU：</div>
              <div>Intel Core i5-8400 or AMD 2500X</div>
            </div>
            <div>
              <div>GPU：</div>
              <div>NVIDIA GTX 1660S or AMD R295X2</div>
            </div>
          </div>
          <div class="installbtn" @click="onClickDownload">DOWNLOAD</div>
        </div>
      </div>
      <div class="rightdiv">
        <div class="robotdiv"></div>
      </div>
    </div>
    <div class="foot">© 2023 Abstracta Labs Pte. Ltd. All rights reserved.</div>
  </div>
  <div class="dialog">
    <el-dialog v-model="dialogVisible" title="BETA v0.75" width="522px" :before-close="handleClose">
      <div>
        <span>Please enter your download code</span>
        <el-input v-model="invitationCode" placeholder="Download Code" />
      </div>
      <template #footer>
        <span class="dialog-footer">
          <div class="myButton" @click="handleClose">Cancel</div>
          <div class="myButton marginleft" @click="handleSure">Confirm</div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {
  onBeforeUnmount,
  ref,
  inject,
  onBeforeMount,
} from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { useStore } from "vuex";


const router = useRouter();
const store = useStore();

const api = inject("$api");
const utils = inject("$utils");

let dialogVisible = ref(false);
let invitationCode = ref("");

const onClickDownload = () => {
  dialogVisible.value = true;
};

const handleClose = () => {
  dialogVisible.value = false;
  invitationCode.value = null;
};

const handleSure = async () => {
  if (invitationCode.value.length <= 0) {
    return;
  }
  let code = invitationCode.value;

  let params = {
    code
  };

  api.downloadInstall(params).then((res) => {
    if (res.errCode === 0) {
      downMedia(res.data);
    } else {
      ElMessage.error(res.errMsg);
    }
    dialogVisible.value = false;
    invitationCode.value = null;
  });

};

const downMedia = async (url) => {
  const link = document.createElement("a"); // 创建一个 a 标签用来模拟点击事件
  link.style.display = "none";
  link.href = url;
  link.target = "_Blank";
  link.setAttribute("download", "文件名");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};



onBeforeMount(() => {
  // verifyInvite();
  // // 验证discord
  // verifyDiscordCode();
});
onBeforeUnmount(() => { });
</script>

<style lang="less" scoped>
.home {
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(@/assets/images/home/bg.png) no-repeat center;

  .div {
    display: flex;
    width: 100%;
    max-width: 1100px;
    justify-content: space-between;

    .leftdiv {
      margin-top: 140px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      z-index: 500;

      .info {
        margin-top: 132px;
        width: 554px;
        font-size: 40px;
        color: #fff;

        span {
          // font-size: 50px;
          // font-weight: bold;
          color: #ffec0e;
        }
      }

      .box2 {
        .infodiv {
          display: flex;
          flex-direction: column;
          color: #fff;
          font-size: 18px;
          margin-top: 30px;

          div {
            display: flex;
            color: #ccc;
            font-size: 13px;

            div:nth-of-type(1) {
              width: 50px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
            }
          }
        }

        .installbtn {
          position: relative;
          width: 306px;
          height: 58px;
          background: url(@/assets/images/btn_1.png) no-repeat center;
          background-size: 306px 58px;
          line-height: 58px;
          text-align: center;
          cursor: pointer;
          font-size: 25px;
          margin-top: 25px;
        }

        .installbtn:hover {
          background: url(@/assets/images/btn_2.png) no-repeat center;
          background-size: 306px 58px;
        }
      }
    }

    .rightdiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 200px;
      left: 50%;

      .robotdiv {
        width: 554px;
        height: 659px;
        // background: url(@/assets/images/home/robot.png) no-repeat center;
        background-size: cover;
      }
    }
  }

  .foot {
    position: absolute;
    bottom: 24px;
    font-size: 13px;
    color: #757474;
  }
}

.myButton {
  width: 110px;
  height: 29px;
  background: url(@/assets/images/btn_1.png) no-repeat center;
  background-size: 110px 29px;
  line-height: 29px;
  text-align: center;
  cursor: pointer;
  right: 0px;
}

.myButton:hover {
  background: url(@/assets/images/btn_2.png) no-repeat center;
  background-size: 110px 29px;
}

.dialog {
  .dialog-footer {
    display: flex;
    justify-content: center;
  }

  .marginleft {
    margin-left: 81px;
  }
}

.dialog /deep/ .el-dialog {
  background: rgba(0, 0, 0, 0.76);
}

.dialog /deep/ .el-dialog__body {
  color: #fff;
}

.dialog /deep/ .el-dialog__title {
  color: #fff;
}
</style>
