import Home from "@/views/PC/Home.vue";
import Ecosystem from "@/views/PC/Ecosystem.vue";
import ItemRes from "@/views/PC/ItemRes.vue";
import Space from "@/views/PC/Space.vue";
import Account from "@/views/PC/Account.vue";
import Register from "@/views/PC/Register.vue";
import Download from "@/views/PC/Download.vue";
import Referral from "@/views/PC/Referral.vue";
import Challenge from "@/views/PC/Challenge.vue";
import StickerVoting from "@/views/PC/StickerVoting.vue";
import BountyHunter from "@/views/PC/BountyHunter.vue";
import IC1101World from "@/views/PC/IC1101World.vue";
import AirDrop from "@/views/PC/AirDrop.vue";

import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/space",
    name: "Space",
    component: Space,
  },
  {
    path: "/ecosystem",
    name: "Ecosystem",
    component: Ecosystem,
  },
  {
    path: "/itemRes",
    name: "ItemRes",
    component: ItemRes,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/ic1101World",
    name: "IC1101World",
    component: IC1101World,
  },
  {
    path: "/download",
    name: "Download",
    component: Download,
  },
  {
    path: "/referral",
    name: "Referral",
    component: Referral,
  },
  {
    path: "/challenge",
    name: "Challenge",
    component: Challenge,
  },
  {
    path: "/stickerVoting",
    name: "StickerVoting",
    component: StickerVoting,
  },
  {
    path: "/bountyHunter",
    name: "BountyHunter",
    component: BountyHunter,
  },
  {
    path: "/airDrop",
    name: "AirDrop",
    component: AirDrop,
  },
  
  {
    path: "/:pathMatch(.*)*",
    component: NotFound,
  },
];

export default routes;
