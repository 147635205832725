/**
 * 使用方法
 * 
 * <div v-t="'basic.welcome'"></div>
 * <div>{{ $t("basic.welcome") }}</div>
 */
import {
  createI18n
} from "vue-i18n";
import zh from "./zh";
import en from "./en";

const i18n = createI18n({
  locale: localStorage.getItem('language') || 'zh', // 定义默认语言为中文
  messages: {
    zh,
    en,
  },
});
export default i18n;