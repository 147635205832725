<template>
  <div class="video-container">
    <video controls autoplay class="video-player">
      <source :src="videoSource" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="video-close" @click="onClose()"></div>
  </div>
</template>

<script setup>
const { defineProps } = require('vue');

const props = defineProps({
  videoSource: String, // video source url
  onClose: Function // function to close the video player
});
</script>

<style lang="less" scoped>
.video-container {
  position: fixed;
  width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  margin-top: 40px;
}

.video-player {
  width: 100%;
  height: auto;
}

.video-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: url(@/assets/images/close.png) no-repeat center;
  background-size: 50px 50px;
}
</style>