
export class OkxEVMApi {
  okxwallet = window["okxwallet"];

  static _instance = null;
  static get instance() {
    if (this._instance == null) {
      this._instance = new OkxEVMApi();
    }
    return this._instance;
  }

  async init(cb) {
    this.okxwallet.on("disconnect", this.disconnectHandler.bind(this));
    this.okxwallet.on("accountsChanged", this.accountChangedHandler.bind(this));
    this.okxwallet.on("chainChanged", this.chainChangedHandler.bind(this));
    if (cb) {
      cb();
    }
  }

  async removeHandler() {
    this.okxwallet.off("disconnect", this.disconnectHandler);
    this.okxwallet.off("accountsChanged", this.accountChangedHandler);
    this.okxwallet.off("chainChanged", this.chainChangedHandler);
  }

  async connect(cb) {
    try {
      let accounts = await this.okxwallet.request({
        method: "eth_requestAccounts",
      });
      cb(accounts[0]);
    } catch (error) {
      this.errorHandler(error);
    }
  }

  async sign(address, cb) {
    try {
      const signStr = `Welcome to IC1101 Exhibition Space!`;
      await this.okxwallet.request({
        method: "personal_sign",
        params: [address, signStr],
      });
      cb();
    } catch (error) {
      this.errorHandler(error);
    }
  }

  // 断开
  disconnectHandler() {
    console.log("disconnected!");
    this.logout();
  }

  // 账户变更
  accountChangedHandler() {
    this.logout();
  }

  // 链更改
  chainChangedHandler() {
    console.log("chainChangedHandler!");
  }

  logout() {
    
  }
}
