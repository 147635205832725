/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
import axios from "axios";
import QS from "qs";
import { ElMessage } from "element-plus";
import store from "@/store/index";
import CryptoJS from "crypto-js";
import utils from "./utils";
import md5 from "js-md5";

export default {
  cryptoFunPost(param, keys) {
  //引入认证信息，看自己是否需要，不需要的话，只要一个get的应该就可以了。
    var authorization = auth.auths.authorization;
    if (authorization != null && authorization != '' && authorization.length == 36) {
      keys = keys + '[' + authorization + ']';
      keys = md5(keys).toString().substring(0, 16);
    }
    var srcs = CryptoJS.enc.Utf8.parse(JSON.stringify(param));
    var key = CryptoJS.enc.Utf8.parse(keys);
    var datas = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return datas.toString();
  },
  cryptoFunGet(param, keys) {
    var srcs = CryptoJS.enc.Utf8.parse(param);
    var key = CryptoJS.enc.Utf8.parse(keys);
    var datas = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
    return datas.toString();
  }
};


// axios.defaults.baseURL = "http://192.168.7.221:7379/new_battle";         //本地服务器
// axios.defaults.baseURL = "http://192.168.7.211:7379/new_battle";         //本地服务器2
axios.defaults.baseURL = "https://meta.ic1101.world/new_battle";         //aws服务器
// axios.defaults.baseURL = "https://dev.wuwai3.com/new_battle";         //阿里云服务器


// 请求超时时间
axios.defaults.timeout = 30000;

// post请求头
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = store.state.token;
    token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 404:
          ElMessage.error("network request does not exist");
          break;
        // 其他错误，直接抛出错误提示
        default:
          ElMessage.error(error.response.data.message);
      }
      return Promise.reject(error.response);
    } else {
      //没有网络
      ElMessage.error("Please check the network");
    }
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  let timestamp = new Date().getTime();
  params.timestamp = timestamp;
  let str = utils.concatenateBattle(params)+'|ad78144c4e331a3e6bb811286247c3ec'
  let sign = String(md5(str).toLowerCase());
  params.sign = sign;
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  let timestamp = new Date().getTime();
  params.timestamp = timestamp;
  let str = utils.concatenateBattle(params)+'|ad78144c4e331a3e6bb811286247c3ec'
  let sign = String(md5(str).toLowerCase());
  params.sign = sign;
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}
